import { OptionType } from '../../common/fields/type';

export type PlayerDatabaseType = {
  id: string;
  organisationId: string;
  firstName: string;
  preferredFirstName: string;
  lastName: string;
  preferredLastName: string;
  juniorTeam: string;
  dateOfBirth: string;
  height: string;
  weight: string;
  position: string;
  secondaryPosition: string;
  state: string;
  preferredFoot: string;
  created: string;
  updated: string;
};

export enum PlayerSelectedTabEnum {
  BIO = 'bio',
  CONTRACT = 'contract',
  ACCOLADE = 'accolade',
  PLAYER_MANAGER = 'playerManager',
  ELIGIBILITY = 'elegibility',
}

export type ContractType = {
  id: string;
  startDate: string;
  endDate: string;
  signingDate: string;
  team: string;
  contractType: string;
  startSeason: string;
  endSeason: string;
  signingAnnoucementSource: string;
  contractEndReason: string;
};

export type AccoladeType = {
  id: string;
  accoladeCategory: string;
  accoladeName: string;
  season: string;
};

export type AccoladeAddUpdateType = {
  season: string;
  accoladeCategory: string;
  accoladeName: string;
};

export type ElegibilityAddUpdateType = {
  season: string;
  elegibilityType: string;
  elegiblityStatus: string;
};

export type ContractAddUpdateType = {
  startDate: string;
  endDate: string;
  signingDate: string;
  team: string;
  contractType: string;
  startSeason: string;
  endSeason: string;
  signingAnnoucementSource: string;
  contractEndReason: string;
};

export type PlayerManagerType = {
  id: string;
  manager: string;
  startDate: string;
  endDate: string;
  company: string;
};

export type ElegibilityType = {
  id: string;
  season: string;
  eligibilityType: string;
  eligibilityStatus: string;
};

export type AccoladeOptionType = {
  category: OptionType[];
  name: Record<string, OptionType[]> | null;
};

export type ElegibilityOptionsType = {
  eligibilityType: OptionType[];
  eligibilityStatus: OptionType[];
};

export type OverlappingDateType = {
  startDate: string;
  endDate: string;
};

export type OverlappingSessionType = {
  startSeason: string;
  endSeason: string;
};
