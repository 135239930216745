/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useGetAccessToken, useGetUserDetail } from '../../store/auth/selector';
import { setCurrentProject, setRefreshFlag } from '../../store/header';
import {
  useCurrentProject,
  useRefreshFlag,
  useSelectedTransactionModal,
  useSideBarStatus,
} from '../../store/header/selector';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { updateProjectList } from '../../store/projects';
import { useLazyGetFlagsToolTipQuery, useLazyGetProjectListQuery } from '../../store/service';
import { transformFlagTooltip, transformProjectDropdownOption, transformProjectList } from './util';
import { useNavigate } from 'react-router-dom';

export const useHeader = () => {
  const { userInfo } = useAppSelector(useGetUserDetail);
  const accessToken = useAppSelector(useGetAccessToken);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [transactionAnchorEl, setTransactionAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [currentProjectAnchorEl, setCurrentProjectAnchorEl] = useState<HTMLButtonElement | null>(
    null,
  );
  const selectedTransactionModal = useAppSelector(useSelectedTransactionModal);
  const dispatch = useAppDispatch();
  const currentProject = useAppSelector(useCurrentProject);
  const isSideBarOpen = useAppSelector(useSideBarStatus);
  const [openPlannerDrawer, setOpenPlannerDrawer] = useState(false);
  const [openPlayerDrawer, setOpenPlayerDrawer] = useState(false);
  const [fetchProjectList, { data }] = useLazyGetProjectListQuery();
  const [fetchToolTip, { data: flagTooltip }] = useLazyGetFlagsToolTipQuery();
  const navigate = useNavigate();
  const refreshFlag = useAppSelector(useRefreshFlag);
  const [showCsvModal, setShowCsvModal] = useState<boolean>(false);
  const [openCreateProject, setOpenCreateProject] = useState(false);

  useEffect(() => {
    accessToken && fetchProjectListData();
  }, [accessToken]);

  const fetchProjectListData = () => {
    fetchProjectList(userInfo?.userId || 0);
  };

  useEffect(() => {
    accessToken &&
      currentProject &&
      (selectedTransactionModal === '' || refreshFlag !== '') &&
      fetchTooltipData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject, refreshFlag, selectedTransactionModal, accessToken]);

  const fetchTooltipData = () => {
    fetchToolTip(parseInt(currentProject)).then(() => {
      dispatch(setRefreshFlag(''));
    });
  };

  useEffect(() => {
    if (data) {
      const transformData = transformProjectList(data);
      currentProject === '' && dispatch(setCurrentProject(transformData[0]?.id || ''));
      dispatch(updateProjectList(transformData));
    }
  }, [currentProject, data, dispatch]);

  const handleOpenProfilePopup = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseProfilePopup = () => {
    setAnchorEl(null);
  };

  const handleOpenTransactionPopup = (event: React.MouseEvent<HTMLButtonElement>) => {
    setTransactionAnchorEl(event.currentTarget);
  };

  const handleCloseTransactionPopup = () => {
    setTransactionAnchorEl(null);
  };

  const updateProjectValue = (value: string) => {
    dispatch(setCurrentProject(value));
    setCurrentProjectAnchorEl(null);
  };

  return {
    username: userInfo?.username || '',
    handleOpenProfilePopup,
    handleCloseProfilePopup,
    anchorEl,
    handleOpenTransactionPopup,
    handleCloseTransactionPopup,
    transactionAnchorEl,
    updateProjectValue,
    currentProject,
    projectDropdownOption: transformProjectDropdownOption(data),
    flagTooltip: transformFlagTooltip(flagTooltip),
    currentProjectAnchorEl,
    setCurrentProjectAnchorEl,
    setOpenPlayerDrawer,
    openPlayerDrawer,
    isSideBarOpen,
    dispatch,
    setOpenPlannerDrawer,
    openPlannerDrawer,
    navigate,
    fetchTooltipData,
    setShowCsvModal,
    showCsvModal,
    setOpenCreateProject,
    openCreateProject,
    refreshtProjectList: fetchProjectListData,
  };
};