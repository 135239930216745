import Popover from '@mui/material/Popover';
import React, { FC } from 'react';
import { OptionType } from '../../../common/fields/type';
import { Box, Grid } from '@mui/material';
import { OptionButton } from '../style';

type Props = {
  anchorEl: HTMLButtonElement | null;
  handleClose: () => void;
  options: OptionType[];
  onSelectOption: (option: string) => void;
};

const CurrentProjectModal: FC<Props> = ({ anchorEl, handleClose, options, onSelectOption }) => {
  return (
    <Popover
      id={'transaction-modal'}
      open
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      PaperProps={{
        style: {
          borderRadius: 20,
          width: 300,
        },
      }}
    >
      <Grid container p={'20px'}>
        {options.map((item) => (
          <Grid borderBottom={'1px grey solid'} item xs={12} key={item.value}>
            <OptionButton
              onClick={() => onSelectOption(item.value)}
              display={'flex'}
              gap={'10px'}
              alignItems={'center'}
            >
              <Box>
                <p>{item.label}</p>
                <p style={{ lineHeight: 0, fontSize: 12 }}>Draft Year - {item.unique}</p>
              </Box>
            </OptionButton>
          </Grid>
        ))}
      </Grid>
    </Popover>
  );
};

export default CurrentProjectModal;
