import { Box, Grid, IconButton } from '@mui/material';
import { Header1, Header2 } from '../../../common/component/Text';
import { PlayerStyledTagNumber, StyledList } from '../style';
import { TeamFlagOptions } from '../../../constant/OptionConstant';
import { BsInstagram, BsTwitter } from 'react-icons/bs';
import { PlayerInfoType } from '../type';
import { differenceInCalendarYears, format } from 'date-fns';
import { capitalize } from 'lodash';
import { TEXT_COLOR_THEME } from '../../../theme/color';

type PlayerInfoSectionProps = {
  bioDetails: PlayerInfoType;
};
const PlayerInfoSection = ({ bioDetails }: PlayerInfoSectionProps) => {
  return (
    <Grid container p={'40px'}>
      <Grid item xs={12}>
        <Grid container alignItems={'center'}>
          <Grid item xs={12}>
            <Grid container columnSpacing={'100px'}>
              <Grid item xs={4}>
                <PlayerStyledTagNumber mb={'20px'}>{bioDetails.id}</PlayerStyledTagNumber>
                <Header1
                  style={{
                    fontSize: 80,
                    lineHeight: '72px',
                    color: TEXT_COLOR_THEME.light,
                  }}
                >
                  {bioDetails.firstName}
                </Header1>
                <Header1
                  style={{
                    fontSize: 80,
                    lineHeight: '72px',
                    color: TEXT_COLOR_THEME.light,
                  }}
                >
                  {bioDetails.lastName}
                </Header1>
              </Grid>
              <Grid item xs={8}>
                <Grid container>
                  <Grid item xs={4} mt={'40px'}>
                    <Header2
                      style={{
                        color: TEXT_COLOR_THEME.light,
                        fontSize: '24px',
                        fontWeight: 'bold',
                      }}
                    >
                      BIO DETAILS
                    </Header2>
                    <ul
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        columnGap: '50px',
                        rowGap: '10px',
                        paddingInlineStart: 20,
                      }}
                    >
                      <StyledList>
                        <Header2 fontcolor={TEXT_COLOR_THEME.light}>
                          Age:{' '}
                          {differenceInCalendarYears(new Date(), new Date(bioDetails.dateOfBirth))}{' '}
                          {format(new Date(bioDetails.dateOfBirth), 'dd MMM, yyyy')}
                        </Header2>
                      </StyledList>
                      <StyledList>
                        <Header2 fontcolor={TEXT_COLOR_THEME.light}>
                          State: {bioDetails.state}
                        </Header2>
                      </StyledList>
                      <StyledList>
                        <Header2 fontcolor={TEXT_COLOR_THEME.light}>
                          Height: {bioDetails.height}cm
                        </Header2>
                      </StyledList>
                      <StyledList>
                        <Header2
                          fontcolor={TEXT_COLOR_THEME.light}
                          display={'flex'}
                          alignItems={'center'}
                          gap={'10px'}
                        >
                          Club:{' '}
                          {
                            TeamFlagOptions.find(
                              (item) => item.id.toString() === bioDetails.juniorTeam.toString(),
                            )?.teamName
                          }{' '}
                          <img
                            height={'30px'}
                            width={'30px'}
                            src={
                              TeamFlagOptions.find(
                                (item) => item.id.toString() === bioDetails.juniorTeam.toString(),
                              )?.flagIcon
                            }
                            alt={
                              TeamFlagOptions.find(
                                (item) => item.id.toString() === bioDetails.juniorTeam.toString(),
                              )?.teamName
                            }
                          />
                        </Header2>
                      </StyledList>
                      <StyledList>
                        <Header2 fontcolor={TEXT_COLOR_THEME.light}>
                          Prefered Foot: {capitalize(bioDetails.preferredFoot)}
                        </Header2>
                      </StyledList>
                    </ul>
                  </Grid>
                  <Grid item xs={4} mt={'40px'}>
                    <Header2
                      style={{
                        color: TEXT_COLOR_THEME.light,
                        fontSize: '24px',
                        fontWeight: 'bold',
                      }}
                    >
                      CONTRACT DETAILS
                    </Header2>
                    <ul
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        columnGap: '50px',
                        rowGap: '10px',
                        paddingInlineStart: 20,
                      }}
                    >
                      <StyledList>
                        <Header2 fontcolor={TEXT_COLOR_THEME.light}>Status: ----</Header2>
                      </StyledList>
                      <StyledList>
                        <Header2 fontcolor={TEXT_COLOR_THEME.light}>Manager: ----</Header2>
                      </StyledList>
                      <StyledList>
                        <Header2 fontcolor={TEXT_COLOR_THEME.light}>Company: ----</Header2>
                      </StyledList>
                      <StyledList>
                        <Header2
                          fontcolor={TEXT_COLOR_THEME.light}
                          display={'flex'}
                          alignItems={'center'}
                          gap={'10px'}
                        >
                          Last Contract Signed: ----
                        </Header2>
                      </StyledList>
                    </ul>
                  </Grid>
                  <Grid item xs={4} mt={'40px'}>
                    <Header2
                      style={{
                        color: TEXT_COLOR_THEME.light,
                        fontSize: '24px',
                        fontWeight: 'bold',
                      }}
                    >
                      DRAFT & TRADING DETAILS
                    </Header2>
                    <ul
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        columnGap: '50px',
                        rowGap: '10px',
                        paddingInlineStart: 20,
                      }}
                    >
                      <StyledList>
                        <Header2 fontcolor={TEXT_COLOR_THEME.light}>Draft Year: ----</Header2>
                      </StyledList>
                      <StyledList>
                        <Header2 fontcolor={TEXT_COLOR_THEME.light}>Draft Selection: </Header2>
                      </StyledList>
                      <StyledList>
                        <Header2 fontcolor={TEXT_COLOR_THEME.light}>Groups: ----</Header2>
                      </StyledList>
                      <StyledList hideDot>
                        <Box display={'flex'} gap={'20px'}>
                          <IconButton>
                            <BsInstagram color={TEXT_COLOR_THEME.light} fontSize={'24px'} />
                          </IconButton>
                          <IconButton>
                            <BsTwitter color={TEXT_COLOR_THEME.light} fontSize={'24px'} />
                          </IconButton>
                        </Box>
                      </StyledList>
                    </ul>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={7}>
                <Box bgcolor={'#f7e3a2'} height={'30px'} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PlayerInfoSection;
