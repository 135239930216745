import { PlayerDatabaseType, PlayerSelectedTabEnum } from '../type';
import CustomModal from '../../../common/component/CustomModal';
import { Grid } from '@mui/material';
import { useAddUpdatePlayer } from '../hook';
import CustomTab from '../../../common/component/CustomTab';
import BioUpdate from './BioUpdate';
import { updatePlayerTabOption } from '../util';
import Contract from './Contract';
import Accolade from './Accolade';
import PlayerManager from './PlayerManager';
import Elegibility from './Elegibility';

type AddUpdatePlayerProps = {
  handleClose: () => void;
  refreshList: () => void;
  playerData?: PlayerDatabaseType;
  initialTab?: PlayerSelectedTabEnum;
  selectedTeam?: string;
};
const AddUpdatePlayer = ({
  handleClose,
  refreshList,
  playerData,
  initialTab,
  selectedTeam,
}: AddUpdatePlayerProps) => {
  const {
    isLoading,
    handleSubmit,
    onSubmit,
    selectedTab,
    control,
    errors,
    register,
    setSelectedTab,
    setValue,
  } = useAddUpdatePlayer(handleClose, refreshList, playerData, initialTab);

  const renderTabSection = () => {
    if (selectedTab === PlayerSelectedTabEnum.BIO) {
      return (
        <BioUpdate setValue={setValue} control={control} errors={errors} register={register} />
      );
    }
    if (!playerData) {
      return null;
    }
    if (selectedTab === PlayerSelectedTabEnum.CONTRACT) {
      return <Contract selectedTeam={selectedTeam} playerId={playerData.id} />;
    }
    if (selectedTab === PlayerSelectedTabEnum.ACCOLADE) {
      return <Accolade playerId={playerData.id} />;
    }
    if (selectedTab === PlayerSelectedTabEnum.PLAYER_MANAGER) {
      return <PlayerManager />;
    }
    return <Elegibility playerId={playerData.id} />;
  };

  return (
    <CustomModal
      modalType='react-hook'
      handleReactFormSubmit={handleSubmit(onSubmit)}
      modalSize='md'
      saveLoading={isLoading}
      confirmButtonLabel={playerData ? 'Update' : 'Save'}
      modalTitle={
        playerData ? `Update ${playerData.firstName} ${playerData.lastName}` : 'Add New Player'
      }
      handleClose={handleClose}
      modalHeight='80vh'
      hideDialogAction={selectedTab !== PlayerSelectedTabEnum.BIO}
    >
      <Grid container spacing={2}>
        {playerData && (
          <Grid item xs={12}>
            <CustomTab
              value={selectedTab}
              tabOption={updatePlayerTabOption}
              setTabSelected={(tab: string) => setSelectedTab(tab as PlayerSelectedTabEnum)}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          {renderTabSection()}
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default AddUpdatePlayer;
