import { FC } from 'react';
import { Box, Grid, Tooltip } from '@mui/material';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import useWindowSize from '../../../common/hooks';
import { PlayerRankingType } from '../type';
import { Header2, Header3, Tag } from '../../../common/component/Text';
import { draftPlayerPositionObj } from '../../../constant/OptionConstant';
import { BG_COLOR, TEXT_COLOR_INFO_TYPE } from '../../../theme/color';

type PlayerRankDatagridProps = {
  list: PlayerRankingType[];
  droppableId: string;
};

const PlayerRankDatagrid: FC<PlayerRankDatagridProps> = ({ list, droppableId }) => {
  const { windowHeight } = useWindowSize();
  const renderComponent = (item: PlayerRankingType) => {
    return (
      <>
        <Box width={'20%'}>
          <Tag textAlign={'center'} width={'20px'} bgcolor={TEXT_COLOR_INFO_TYPE.neutral}>
            {item.rank}
          </Tag>
        </Box>

        <Box width={'70%'}>
          <Header2 textsize='12px' style={{ lineHeight: '8px' }} fontweight={700}>
            {item.fullName}
          </Header2>
          <Tooltip title={item.club}>
            <Header3
              style={{
                fontWeight: 500,
                lineHeight: 'normal',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '1',
                WebkitBoxOrient: 'vertical',
              }}
              textsize={'12px'}
            >
              {item.club}
            </Header3>
          </Tooltip>
        </Box>
        <Box width={'20%'}>
          <Tooltip placement='right-start' title={item.position1}>
            <Tag
              textAlign={'center'}
              width={'20px'}
              textsize='12px'
              height={'fit-content'}
              bgcolor={draftPlayerPositionObj?.[item.position1]?.color || ''}
            >
              {draftPlayerPositionObj?.[item.position1]?.text || item.position1}
            </Tag>
          </Tooltip>
        </Box>
      </>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Droppable droppableId={droppableId}>
          {({ droppableProps, innerRef, placeholder }) => {
            return (
              <Box
                overflow={'auto'}
                height={`${windowHeight - 220}px`}
                ref={innerRef}
                {...droppableProps}
              >
                {list.map((item, index) => (
                  <Draggable draggableId={item.id.toString()} key={item.id} index={index}>
                    {({ draggableProps, innerRef, dragHandleProps }) => (
                      <Box
                        bgcolor={BG_COLOR.lightDark}
                        ref={innerRef}
                        border={'1px #efefef solid'}
                        height={`${(windowHeight - 500) / list.length - 1}px`}
                        key={index}
                        maxHeight={'18px'}
                        display={'flex'}
                        gap={'20px'}
                        padding={'10px'}
                        alignItems={'center'}
                        {...draggableProps}
                        {...dragHandleProps}
                      >
                        {renderComponent(item)}
                      </Box>
                    )}
                  </Draggable>
                ))}
                {placeholder}
              </Box>
            );
          }}
        </Droppable>
      </Grid>
    </Grid>
  );
};

export default PlayerRankDatagrid;
