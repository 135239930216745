import CustomModal from '../../../common/component/CustomModal';
import { ElegibilityType } from '../type';
import { useAddUpdateElegibility } from '../hook';
import { Grid } from '@mui/material';
import CustomSelect from '../../../common/fields/reacthookfield/CustomSelect';
import { getYearOptions } from '../../../util/SiteHelper';

type AddUpdateElegibilityProps = {
  handleClose: () => void;
  selectedElegibility?: ElegibilityType;
  playerId: string;
  refreshList: () => void;
};

const AddUpdateElegibility = ({
  handleClose,
  playerId,
  refreshList,
  selectedElegibility,
}: AddUpdateElegibilityProps) => {
  const {
    errors,
    handleSubmit,
    isLoading,
    onSubmit,
    register,
    updateLoading,
    elegibilitySeason,
    elegibilityType,
    elegiblityStatus,
    elegibilitOptionsList,
  } = useAddUpdateElegibility(playerId, refreshList, selectedElegibility);
  return (
    <CustomModal
      modalTitle={selectedElegibility ? 'Update Elegibility' : 'Add Elegibility'}
      modalSize='xs'
      handleSubmit={handleSubmit(onSubmit)}
      handleClose={handleClose}
      saveLoading={isLoading || updateLoading}
    >
      <Grid container>
        <Grid item xs={12}>
          <CustomSelect
            options={elegibilitOptionsList.eligibilityType}
            id='elegibilityType'
            name={'elegibilityType'}
            register={register}
            isMandatory
            errors={errors}
            value={elegibilityType}
            fullWidth
            variant='outlined'
            label={'Type'}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomSelect
            id='elegiblityStatus'
            options={elegibilitOptionsList.eligibilityStatus}
            name={'elegiblityStatus'}
            register={register}
            isMandatory
            value={elegiblityStatus}
            errors={errors}
            fullWidth
            variant='outlined'
            label={'Status'}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomSelect
            options={getYearOptions()}
            id='season'
            name={'season'}
            register={register}
            isMandatory
            errors={errors}
            fullWidth
            value={elegibilitySeason}
            variant='outlined'
            label={'Season'}
          />
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default AddUpdateElegibility;
