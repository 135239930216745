export const routeUrl = {
  dashboard: '/dashboard',
  draftPick: '/draftpick',
  tradeAnalysis: '/tradeAnalysis',
  draftBoard: '/draftboard',
  draftComparision: '/draftComparison',
  updateLadder: '/updateLadder',
  projectList: '/projectList',
  scenarioPlanning: '/scenarioplanning',
  smartList: '/smartlist',
  tradeOffer: '/tradeoffer',
  listProjection: '/listprojection',
  tpp: '/tpp',
  contractStatus: '/contractstatus',
  playerDatabase: '/playerdatabase',
  playerReports: '/playerreports',
  playerHome: '/playerHome',
  organisation: '/organisation',
  playerManager: '/playermanager',
  playerHomeInfo: '/playerhome/:playerId',
  playerRanking: '/playerranking',
};
