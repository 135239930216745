/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { useLazyRoasterAllocationYearTeamInfoQuery } from '../../store/service/clubsummaryservice';
import { useAppSelector } from '../../store/hooks';
import { useCurrentOrganisation } from '../../store/header/selector';
import { transformSmartListPlayer, trasnformPlayerListOptions } from './util';
import {
  useCreateTimelineEntryMutation,
  useGetTimelineEntryFieldQuery,
  useLazyGetPlayerDatabaseQuery,
  useLazyGetPlayerTimelineQuery,
} from '../../store/service/playerdatabaseservice';
import { PlayerDatabaseType } from '../playerdatabase/type';
import { transformPlayerList } from '../playerdatabase/util';
import { useGetUserTeam } from '../../store/auth/selector';
import { useGetShowTeamQuery } from '../../store/service';
import { OptionType } from '../../common/fields/type';
import { transformTeamNameDropdownOption } from '../scenarioplanning/util';
import { FieldsType } from '../playerinfo/type';
import { transformFieldDetails, transformTimelineData } from '../playerinfo/util';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';
import { CreateContractType } from './type';

export const useSmartList = () => {
  const { data: showTeamData } = useGetShowTeamQuery('');
  const [fetchSummaryList, { data, isLoading, error }] =
    useLazyRoasterAllocationYearTeamInfoQuery();
  const [selectedTeam, setSelectedTeam] = useState('');
  const currentOrgId = useAppSelector(useCurrentOrganisation);
  const [fetchPlayerList, { data: playerList }] = useLazyGetPlayerDatabaseQuery();
  const [selectedPlayerForEdit, setSelectedPlayerForEdit] = useState<
    PlayerDatabaseType | undefined
  >(undefined);
  const [selectedPlayerForAddNote, setSelectedPlayerForAddNote] = useState<
    PlayerDatabaseType | undefined
  >(undefined);
  const [addContract, setHandleAddContract] = useState<CreateContractType | undefined>(undefined);
  const userTeamId = useAppSelector(useGetUserTeam);
  const [teamOptions, setTeamOptions] = useState<OptionType[]>([]);
  const smartListData = useMemo(
    () => transformSmartListPlayer(error ? undefined : data),
    [data, error],
  );

  useEffect(() => {
    if (showTeamData) {
      const teamData = transformTeamNameDropdownOption(showTeamData?.data);
      setTeamOptions(teamData);
      userTeamId && setSelectedTeam(userTeamId.toString());
    }
  }, [showTeamData]);

  useEffect(() => {
    selectedTeam && handleFetchSummaryList();
    if (currentOrgId !== '') {
      fetchPlayerList(currentOrgId);
      return;
    }
  }, [selectedTeam]);

  const handleFetchSummaryList = () => {
    fetchSummaryList({
      orgId: currentOrgId,
      teamId: selectedTeam,
    });
  };

  const playerListOption = useMemo(() => transformPlayerList(playerList), [playerList]);
  return {
    selectedTeam,
    setSelectedTeam,
    isLoading,
    smartListData,
    selectedPlayerForEdit,
    setSelectedPlayerForEdit,
    refreshData: handleFetchSummaryList,
    playerListInfo: playerListOption,
    playerListOption: trasnformPlayerListOptions(playerListOption),
    teamOptions,
    selectedPlayerForAddNote,
    setSelectedPlayerForAddNote,
    setHandleAddContract,
    addContract,
  };
};

export const useAddTimelineNote = (playerId: string) => {
  const { data } = useGetTimelineEntryFieldQuery('');
  const [createTimeline, { isLoading: createTimeLineLoading }] = useCreateTimelineEntryMutation();
  const currentOrgId = useAppSelector(useCurrentOrganisation);
  const [selectedFormFieldInfo, setSelectedFormField] = useState<FieldsType | undefined>();
  const [fetchPlayerTimeline, { data: timelineEntry }] = useLazyGetPlayerTimelineQuery();
  const transformFieldInfo: FieldsType[] = useMemo(() => transformFieldDetails(data), [data]);

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    loadForm();
  }, [transformFieldInfo]);

  const loadForm = () => {
    const loadField = transformFieldInfo.find((item) => item.name === 'LIST_MANAGEMENT_NOTE');
    setSelectedFormField(loadField);
    let fieldDetails: Record<string, string | number | null> = {};

    loadField?.fields.forEach((field) => {
      fieldDetails[field.fieldId] = field.value;
    });
    reset(fieldDetails);
  };

  const fetchData = () => {
    playerId &&
      fetchPlayerTimeline({
        orgId: currentOrgId,
        playerId,
      });
  };

  const onSubmit = (value: any) => {
    const payload = Object.entries(value).map((item: any) => ({
      id: item[0],
      value: typeof item[1] === 'object' ? format(new Date(item[1]), 'yyyy-MM-dd') : item[1],
    }));
    playerId &&
      createTimeline({
        data: payload,
        formName: 'LIST_MANAGEMENT_NOTE',
        orgId: currentOrgId,
        playerId: playerId,
      }).then((res: any) => {
        if (!res.error) {
          fetchData();
          return;
        }
      });
  };

  const transformTimelineListData = useMemo(
    () => transformTimelineData(timelineEntry),
    [timelineEntry],
  );

  return {
    data: transformFieldInfo,
    isLoading: createTimeLineLoading,
    loadForm,
    selectedFormFieldInfo,
    register,
    errors,
    handleSubmit,
    onSubmit,
    control,
    timelineEntry: transformTimelineListData,
  };
};
