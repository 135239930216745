import { Grid } from '@mui/material';
import CustomTab from '../../../common/component/CustomTab';
import { playerInfoTabOption } from '../util';
import { usePlayerMoreInfo } from '../hook';
import { PLAYER_INFO_TAB } from '../type';
import PlayerAwards from './PlayerAwards';
import PlayerContractHistory from './PlayerContractHistory';
import PlayerTimeline from './PlayerTimeline';

const PlayerMoreInfo = () => {
  const { selectedTab, setSelectedTab } = usePlayerMoreInfo();

  const renderView = () => {
    if (selectedTab === PLAYER_INFO_TAB.AWARDS) {
      return <PlayerAwards />;
    }
    if (selectedTab === PLAYER_INFO_TAB.CONTRACT_HISTORY) {
      return <PlayerContractHistory />;
    }
    return <PlayerTimeline />;
  };
  return (
    <Grid container mt={'20px'}>
      <Grid item xs={12}>
        <CustomTab
          value={selectedTab}
          centered
          setTabSelected={(tab) => setSelectedTab(tab as PLAYER_INFO_TAB)}
          tabOption={playerInfoTabOption}
        />
      </Grid>
      <Grid item xs={12} minHeight={'50vh'} mt={'20px'}>
        {renderView()}
      </Grid>
    </Grid>
  );
};

export default PlayerMoreInfo;
