/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import {
  useCreateNewRankingPlayerMutation,
  useDeleteRankingMutation,
  useLazyGetRankingListDataQuery,
  useLazyGetRankingListQuery,
  useUpdateRankingListMutation,
  useUpdateRankingSingleMutation,
} from '../../store/service/draftboardservice';
import { useForm } from 'react-hook-form';
import {
  PlayerRakingInitialValue,
  PlayerValidationSchema,
  transformPlayerRanking,
  transformRankingList,
} from './util';
import { yupResolver } from '@hookform/resolvers/yup';
import { RankingListType, RankingUpdateListType, UpdateRankingType } from './type';
import { useLazyGetPlayerDatabaseQuery } from '../../store/service/playerdatabaseservice';
import { useCurrentOrganisation } from '../../store/header/selector';
import { useAppSelector } from '../../store/hooks';
import { toast } from 'react-toastify';
import { transformPlayerList } from '../playerdatabase/util';
import { getTierNumber } from '../draftboard/util';

export const usePlayerRanking = () => {
  const [fetchRankingList, { data }] = useLazyGetRankingListQuery();
  const [selectedPlayerRanking, setSelectedPlayerRanking] = useState('');
  const [openRankingListModal, setOpenRankingLisModal] = useState(false);
  const [fetchPlayerData, { data: playerRankingData, isLoading: playerRankingListLoading }] =
    useLazyGetRankingListDataQuery();
  const [refreshListKey, setRefreshListKey] = useState(Math.random().toString());

  useEffect(() => {
    fetchRankingListData();
  }, []);

  const [updatePlayerSingleRanking] = useUpdateRankingSingleMutation();

  useEffect(() => {
    fetchSelectedPlayerRankingData();
  }, [selectedPlayerRanking]);

  const selectedRankingData = useMemo(
    () => transformPlayerRanking(playerRankingData),
    [playerRankingData],
  );
  const fetchSelectedPlayerRankingData = () => {
    selectedPlayerRanking &&
      fetchPlayerData(selectedPlayerRanking).then(() => {
        setRefreshListKey(Math.random().toString());
      });
  };
  const fetchRankingListData = () => {
    fetchRankingList('');
  };

  useEffect(() => {
    data && setSelectedPlayerRanking(transformRankingList(data)?.[0]?.id.toString() || '');
  }, [data]);

  const updatePlayerPos = (playerInfo: UpdateRankingType) => {
    selectedPlayerRanking &&
      updatePlayerSingleRanking({
        data: {
          playerId: playerInfo.playerId,
          ranking: !playerInfo?.ranking ? 1 : playerInfo?.ranking,
          tier: getTierNumber[playerInfo.tier],
        },
        rankingId: selectedPlayerRanking,
      }).then((res: any) => {
        if (!res.error) {
          fetchSelectedPlayerRankingData();
          setRefreshListKey(Math.random().toString());
        }
      });
  };

  return {
    data,
    setSelectedPlayerRanking,
    selectedPlayerRanking,
    setOpenRankingLisModal,
    openRankingListModal,
    refreshList: fetchRankingListData,
    rankingList: transformRankingList(data),
    selectedRankingData,
    playerRankingListLoading,
    refreshListKey,
    setRefreshListKey,
    updatePlayerPos,
  };
};

export const useRankingCreateUpdateForm = ({
  refreshList,
  handleClose,
  selectedRankingItem,
}: {
  refreshList: () => void;
  handleClose: () => void;
  selectedRankingItem?: RankingListType | null;
}) => {
  const [createRanking, { isLoading: createLoading }] = useCreateNewRankingPlayerMutation();
  const [updateRanking, { isLoading: updateLoading }] = useUpdateRankingListMutation();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: PlayerRakingInitialValue(selectedRankingItem),
    resolver: yupResolver(PlayerValidationSchema),
  });

  const onSubmit = (value: RankingUpdateListType) => {
    if (selectedRankingItem) {
      updateRanking({
        data: value,
        rankingId: selectedRankingItem.id.toString(),
      }).then((res: any) => {
        if (!res.error) {
          refreshList();
          handleClose();
        }
      });
      return;
    }
    createRanking({
      data: value,
    }).then((res: any) => {
      if (!res.error) {
        refreshList();
        handleClose();
      }
    });
  };

  return {
    control,
    handleSubmit,
    onSubmit,
    isLoading: updateLoading || createLoading,
    register,
    errors,
  };
};

export const useRankingListModal = ({ refreshList }: { refreshList: () => void }) => {
  const [deleteRanking, { isLoading }] = useDeleteRankingMutation();
  const [openUpdateModal, setUpdateModal] = useState(false);
  const [selectedRankingItem, setSelectRankingItem] = useState<RankingListType | null>(null);
  const [selectedRankingItemForDelete, setSelectRankingItemForDelete] =
    useState<RankingListType | null>(null);

  const deleteRankingList = () => {
    selectedRankingItemForDelete &&
      deleteRanking({
        rankingId: selectedRankingItemForDelete.id.toString(),
      }).then((res: any) => {
        if (!res.error) {
          refreshList();
          setSelectRankingItemForDelete(null);
        }
      });
  };

  return {
    openUpdateModal,
    setUpdateModal,
    selectedRankingItem,
    setSelectRankingItem,
    selectedRankingItemForDelete,
    setSelectRankingItemForDelete,
    deleteRankingList,
    isLoading,
  };
};

export const usePlayerList = () => {
  const [fetchData, { data, isLoading }] = useLazyGetPlayerDatabaseQuery();
  const currentOrgId = useAppSelector(useCurrentOrganisation);

  useEffect(() => {
    fetchPlayerData();
  }, [currentOrgId]);

  const fetchPlayerData = () => {
    if (currentOrgId !== '') {
      fetchData(currentOrgId);
      return;
    }
    toast.error('Please select organisation');
  };

  return {
    playerList: transformPlayerList(data),
    isLoading,
  };
};
