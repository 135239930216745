export enum PLAYER_INFO_TAB {
  TIMELINE = 'timeline',
  CONTRACT_HISTORY = 'contractHistory',
  AWARDS = 'awards',
}

export type PlayerInfoType = {
  id: number;
  organisationId: number;
  firstName: string;
  preferredFirstName: string;
  lastName: string;
  preferredLastName: string;
  juniorTeam: string;
  dateOfBirth: string;
  height: number;
  weight: number;
  position: string;
  secondaryPosition: string;
  state: string;
  preferredFoot: string;
  created: string;
  updated: string;
};

export type FieldInfoType = {
  fieldId: string;
  fieldLabel: string;
  fieldName: string;
  fieldTooltip: string;
  fieldType: string;
  maxLength: number;
  minLength: number;
  minValue: number;
  maxValue: number;
  nullable: boolean;
  value: string | number | null;
};
export type FieldsType = {
  active: boolean;
  name: string;
  fields: FieldInfoType[];
};

export type TimeLineScehmaType = {
  fieldId: string;
  fieldLabel: string;
  fieldName: string;
  fieldTooltip: string;
  fieldType: string;
  nullable: boolean;
};

export type TimeLineFieldType = {
  id: string;
  value: string;
  schema: TimeLineScehmaType;
};

export type TimelineOfEntryType = {
  id: number;
  name: string;
  orgId: number;
  playerId: number;
  fields: TimeLineFieldType[];
  createdAt: string;
  updatedAt: string;
  createdBy: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
  };
};

export type FilterOptionType = {
  open: boolean;
  formType: string;
  createdAt: {
    startDate: string | null;
    endDate: string | null;
  };
  updatedAt: {
    startDate: string | null;
    endDate: string | null;
  };
};
