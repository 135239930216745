import { Grid } from '@mui/material';
import Contract from '../../playerdatabase/component/Contract';
import { useParams } from 'react-router-dom';

const PlayerContractHistory = () => {
  const { playerId } = useParams();
  return (
    <Grid item xs={12}>
      {playerId && (
        <>
          <Contract playerId={playerId} />
        </>
      )}
    </Grid>
  );
};

export default PlayerContractHistory;
