import { FC, useEffect, useRef, useState } from 'react';
import { ClubSummaryPlayerType, CreateContractType, SummaryDataType } from '../type';
import { Box, IconButton, Typography } from '@mui/material';
import { AddCircle, ArrowLeft, ArrowRight } from '@mui/icons-material';
import { PrimaryTextButton } from '../../../common/component/Button';
import SearchPlayerTextfield from './SearchPlayerTextfield';
import { OptionType } from '../../../common/fields/type';
import { BG_COLOR } from '../../../theme/color';
import RowHeader from './RowHeader';
import ContractColumn from './ContractColumn';

type SmartPlayerListProps = {
  playerList: ClubSummaryPlayerType[];
  columns: string[];
  loading: boolean;
  playerListOption: OptionType[];
  onEditClick: (playerId: number) => void;
  onAddNoteClick: (playerId: number) => void;
  summaryList: SummaryDataType[];
  onYearAddContractClick: (contractInfo: CreateContractType) => void;
};

const SmartPlayerList: FC<SmartPlayerListProps> = ({
  playerList,
  columns,
  loading,
  playerListOption,
  onEditClick,
  onAddNoteClick,
  summaryList,
  onYearAddContractClick,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [showSearchPlayerField, setShowSearchPlayerField] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState('');

  useEffect(() => {
    const container = containerRef.current;
    const year = new Date().getFullYear();

    if (container) {
      const ele = document.getElementById(`${year}`);
      if (ele) {
        const left = ele.offsetLeft - 40;

        container.scrollTo({
          behavior: 'smooth',
          left: left,
        });
      }
    }
  }, [loading]);

  const handleScrollRight = () => {
    const container = containerRef.current;
    if (container && container.scrollLeft < container.scrollWidth) {
      const scrollLeft = container.scrollLeft + 180;
      container.scrollTo({
        behavior: 'smooth',
        left: scrollLeft,
      });
    }
  };

  const handleScrollLeft = () => {
    const container = containerRef.current;
    if (container && container.scrollLeft >= 0) {
      const scrollLeft = container.scrollLeft - 180;
      container.scrollTo({
        behavior: 'smooth',
        left: scrollLeft,
      });
    }
  };

  return (
    <Box>
      <Box bgcolor={BG_COLOR.white200} display={'flex'}>
        <Box>
          <Box bgcolor={BG_COLOR.white200} p={'20px'}>
            <Typography width={'200px'} fontWeight={900} fontSize={'12px'}>
              Player Name
            </Typography>
          </Box>
          <RowHeader title={'Primary List'} />
          <RowHeader title={'Rookie List'} />
          <RowHeader title={'Total'} />
          {playerList.map((item) => (
            <RowHeader
              title={item.playerName}
              key={item.id}
              onAddNoteClick={() => onAddNoteClick(item.id)}
              onEditNoteClick={() => onEditClick(item.id)}
            />
          ))}
          <Box bgcolor={BG_COLOR.white}>
            {showSearchPlayerField ? (
              <Box px={'5px'} py={'5px'}>
                <SearchPlayerTextfield
                  options={playerListOption}
                  selectedPlayer={selectedPlayer}
                  setSelectedPlayer={(id) => {
                    setSelectedPlayer(id);
                    onEditClick(Number(id));
                  }}
                  setShowSearchPlayerField={setShowSearchPlayerField}
                />
              </Box>
            ) : (
              <Box py={'5px'}>
                <PrimaryTextButton
                  sx={{
                    borderRadius: 0,
                    textTransform: 'capitalize',
                  }}
                  onClick={() => setShowSearchPlayerField(true)}
                  endIcon={<AddCircle />}
                  title='Click To Add player'
                />
              </Box>
            )}
          </Box>
        </Box>
        <Box
          ref={containerRef}
          width={'100%'}
          position={'relative'}
          display={'flex'}
          overflow={'auto'}
          justifyContent={'space-between'}
        >
          <Box position={'sticky'} left={0}>
            <IconButton
              sx={{
                borderRadius: 0,
                bgcolor: BG_COLOR.white,
                height: '64px',
                '&:hover': {
                  bgcolor: BG_COLOR.white,
                },
              }}
              onClick={handleScrollLeft}
            >
              <ArrowLeft />
            </IconButton>
          </Box>

          {columns.map((year) => (
            <ContractColumn
              summaryList={summaryList}
              key={year}
              handlePlayerYearClick={(player) =>
                onYearAddContractClick({
                  playerId: player.playerId,
                  contracts: player.contracts,
                  selectedYear: player.selectedYear,
                })
              }
              year={year}
              playerList={playerList}
            />
          ))}
          <Box position={'sticky'} right={0}>
            <IconButton
              sx={{
                borderRadius: 0,
                bgcolor: BG_COLOR.white,
                height: '64px',
                '&:hover': {
                  bgcolor: BG_COLOR.white,
                },
              }}
              onClick={handleScrollRight}
            >
              <ArrowRight />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SmartPlayerList;
