import { Box, Grid } from '@mui/material';
import { StyledCard } from '../../../common/component/Card';
import { Header2, Header3 } from '../../../common/component/Text';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { BG_COLOR } from '../../../theme/color';
import { PlayerDatabaseType } from '../../playerdatabase/type';

type PlayerListProps = {
  playerList: PlayerDatabaseType[];
};

const PlayerList = ({ playerList }: PlayerListProps) => {
  return (
    <StyledCard container>
      <Header3>Player List</Header3>
      <Droppable droppableId={'player-list'}>
        {({ droppableProps, innerRef, placeholder }) => {
          return (
            <Box ref={innerRef} {...droppableProps}>
              {playerList?.map((item, index) => (
                <Grid mt={'5px'} item xs={12} key={item.id}>
                  <Draggable draggableId={item.id.toString()} key={item.id} index={index}>
                    {({ draggableProps, innerRef, dragHandleProps }) => (
                      <Box
                        bgcolor={BG_COLOR.white}
                        ref={innerRef}
                        border={'1px #efefef solid'}
                        key={index}
                        maxHeight={'18px'}
                        display={'flex'}
                        gap={'20px'}
                        padding={'10px'}
                        alignItems={'center'}
                        {...draggableProps}
                        {...dragHandleProps}
                      >
                        <Header2>
                          {item.firstName} {item.lastName}
                        </Header2>
                      </Box>
                    )}
                  </Draggable>
                </Grid>
              ))}
            </Box>
          );
        }}
      </Droppable>
    </StyledCard>
  );
};

export default PlayerList;
