import { object, string } from 'yup';
import { PlayerRankingType, RankingListType, RankingPlayerInfoType } from './type';
import { PlayerDatabaseType } from '../playerdatabase/type';

export const PlayerRakingInitialValue = (selectedRankingItem?: RankingListType | null) => ({
  draftYear: selectedRankingItem?.draftYear ? `01/01/${selectedRankingItem.draftYear}` : '',
  name: selectedRankingItem?.name || '',
});

export const PlayerValidationSchema = object().shape({
  draftYear: string().required('Please select draft Year'),
  name: string().required('Please enter name'),
});

export const transformRankingList = (data: any): RankingListType[] => {
  if (!data) {
    return [];
  }

  return data.map(
    (item: any) =>
      ({
        created: item.created,
        draftYear: item.draft_year,
        id: item.id,
        name: item.name,
        updated: item.updated,
      } as RankingListType),
  );
};

export const transformPlayerRankList = (data: any): PlayerRankingType[] => {
  if (!data) {
    return [];
  }
  return data.map((item: any) => ({
    firstName: item.player.first_name,
    fullName: `${item.player.first_name} ${item.player.last_name}`,
    height: item.player.height,
    position1: item.player.position,
    position2: item.player.secondary_position,
    rank: item.ranking,
    state: item.player.state,
    tier: item.tier,
    weight: item.player.weight,
    club: item.player.junior_team,
    id: item.player.id,
  }));
};

export const transformPlayerRanking = (data: any): RankingPlayerInfoType | null => {
  if (!data) {
    return null;
  }
  return {
    created: data.created,
    draftYear: data.draft_year,
    id: data.id,
    name: data.name,
    ranks: transformPlayerRankList(data.ranks),
    updated: data.updated,
  };
};

export const sortPlayerListByRank = (playerList: PlayerRankingType[]): PlayerRankingType[] => {
  return playerList.sort((a, b) => Number(a.rank) - Number(b.rank));
};

export const moveElementToPosition = (
  arr: PlayerRankingType[],
  element: PlayerRankingType,
  newPosition: number,
) => {
  let copyArr = arr;
  if (newPosition < 0) {
    newPosition = 0;
  } else if (newPosition > arr.length) {
    newPosition = copyArr.length;
  }
  const findItem = arr.find((item) => item.id === element.id);
  if (findItem) {
    copyArr = copyArr.filter((el) => el !== findItem);
  }

  copyArr.splice(newPosition, 0, element);
  return copyArr;
};

export const transformToPlayerRankingData = (
  data: PlayerDatabaseType,
  rank: number,
  tier: number,
): PlayerRankingType => {
  return {
    club: data.juniorTeam,
    firstName: data.firstName,
    fullName: `${data.firstName} ${data.lastName}`,
    height: data.height,
    id: Number(data.id),
    position1: data.position,
    position2: data.secondaryPosition,
    rank: rank,
    state: data.state,
    tier: tier,
    weight: data.weight,
  };
};
