/* eslint-disable react-hooks/exhaustive-deps */
import { ContractType, OverlappingSessionType } from '../type';
import { useAddUpdateContract } from '../hook';
import CustomModal from '../../../common/component/CustomModal';
import { Grid } from '@mui/material';
import CustomSelect from '../../../common/fields/reacthookfield/CustomSelect';
import CustomeDatepicker from '../../../common/fields/reacthookfield/CustomeDatepicker';
import { TeamFlagOptions } from '../../../constant/OptionConstant';
import { OptionType } from '../../../common/fields/type';
import CustomTextInput from '../../../common/fields/reacthookfield/CustomTextInput';
import CustomRadioGroup from '../../../common/fields/reacthookfield/CustomRadioGroup';
import { ContractEndReasonRadioOption } from '../../transaction/component/passpicks/util';
import { add, sub } from 'date-fns';

type AddUpdateContractProps = {
  handleClose: () => void;
  selectedContract?: ContractType;
  playerId: string;
  refreshList: () => void;
  selectedTeam?: string;
  startSeason?: string;
  seasonList: OverlappingSessionType[];
};
const AddUpdateContract = ({
  handleClose,
  playerId,
  refreshList,
  selectedContract,
  selectedTeam,
  startSeason,
  seasonList,
}: AddUpdateContractProps) => {
  const {
    isLoading,
    errors,
    catOpt,
    handleSubmit,
    register,
    onSubmit,
    contractType,
    control,
    team,
  } = useAddUpdateContract({
    playerId,
    refreshList,
    contractInfo: selectedContract,
    selectedTeam,
    selectedStartSeason: startSeason,
    seasonList,
  });

  return (
    <CustomModal
      modalTitle={selectedContract ? 'Update Contract' : 'Add Contract'}
      modalSize='xs'
      handleSubmit={handleSubmit(onSubmit)}
      handleClose={handleClose}
      saveLoading={isLoading}
    >
      <Grid container columnSpacing={'20px'} rowSpacing={'10px'}>
        <Grid item xs={6}>
          <CustomeDatepicker
            isMandatory
            control={control}
            fieldLabel={'Start Season'}
            name={'startSeason'}
            views={['year']}
            format='yyyy'
            defaultDay={1}
            defaultMonth={1}
            minDate={sub(new Date(), {
              years: 10,
            })}
            maxDate={add(new Date(), {
              years: 10,
            })}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomeDatepicker
            isMandatory
            control={control}
            fieldLabel={'End Season'}
            name={'endSeason'}
            views={['year']}
            defaultDay={1}
            format='yyyy'
            defaultMonth={1}
            minDate={sub(new Date(), {
              years: 10,
            })}
            maxDate={add(new Date(), {
              years: 10,
            })}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomeDatepicker
            isMandatory
            control={control}
            fieldLabel={'Start Date'}
            name={'startDate'}
            views={['year']}
            defaultDay={1}
            defaultMonth={1}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomeDatepicker
            isMandatory
            control={control}
            fieldLabel={'End Date'}
            name={'endDate'}
            views={['year']}
            // key={endDate}
            defaultDay={31}
            defaultMonth={10}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomeDatepicker
            isMandatory
            control={control}
            fieldLabel={'Signing Date'}
            name={'signingDate'}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomSelect
            options={TeamFlagOptions.map(
              (item) =>
                ({
                  label: item.teamName,
                  value: item.id.toString(),
                } as OptionType),
            )}
            id='team'
            name={'team'}
            register={register}
            isMandatory
            errors={errors}
            value={team}
            fullWidth
            variant='outlined'
            label={'Team'}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomSelect
            options={catOpt}
            id='contractType'
            name={'contractType'}
            register={register}
            isMandatory
            errors={errors}
            value={contractType}
            fullWidth
            variant='outlined'
            label={'Contract Type'}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextInput
            name={'signingAnnoucementSource'}
            register={register}
            errors={errors}
            fullWidth
            isMandatory
            variant='outlined'
            label={'Signing Announcement Source'}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomRadioGroup
            control={control}
            name='contractEndReason'
            label={'Contract End Reason'}
            row
            isClearable
            radioOption={ContractEndReasonRadioOption}
          />
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default AddUpdateContract;
