export const DRAFT_PICK_HIGHLIGHTED_TEAM = 40;
export const PLAYERS_HOME_FLAG = 'enablePlayerHome';
export const PLAYER_REPORT_FLAG = 'enablePlayerReport';
export const CONTRACT_STATUS_FLAG = 'enableContractStatus';
export const TPP_FLAG = 'enableTpp';
export const LIST_PROJECTION_FLAG = 'enableListProjection';
export const ORGANISATION_FLAG = 'enableOrganisation';
export const USERS_FLAG = 'enableUsers';
export const PERMISSIONS_FLAG = 'enablePermission';
export const CLUB_SUMMARY_FLAG = 'clubsummary';
