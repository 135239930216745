import { OptionType } from '../common/fields/type';
import { TradeStatus } from '../container/planner/type';
import { DRAFT_BOARD_TAG_COLOR } from '../theme/color';

export const draftPlayerPositionObj: Record<
  string,
  {
    text: string;
    color: string;
  }
> = {
  Mid: {
    text: 'M',
    color: DRAFT_BOARD_TAG_COLOR.mid,
  },
  Fwd: {
    text: 'F',
    color: DRAFT_BOARD_TAG_COLOR.keFwd,
  },
  KeyFwd: {
    text: 'KF',
    color: DRAFT_BOARD_TAG_COLOR.keFwd,
  },
  Def: {
    text: 'D',
    color: DRAFT_BOARD_TAG_COLOR.keyDef,
  },
  KeyDef: {
    text: 'KD',
    color: DRAFT_BOARD_TAG_COLOR.keyDef,
  },
  Ruck: {
    text: 'R',
    color: DRAFT_BOARD_TAG_COLOR.ruck,
  },
  Wing: {
    text: 'W',
    color: DRAFT_BOARD_TAG_COLOR.ruck,
  },
};

export const PickEditOption: OptionType[] = [
  {
    label: 'Carry Over Trade',
    value: 'Carry Over Trade',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const InsertInstructionOption: OptionType[] = [
  {
    label: 'Before',
    value: 'Before',
  },
  {
    label: 'After',
    value: 'After',
  },
];

const getYearsOptions = (): OptionType[] => {
  let currentyear = new Date().getFullYear() - 1;
  let yearsCalculate = [];
  for (var i = currentyear; i < currentyear + 6; i++) {
    yearsCalculate.push(i);
  }
  return yearsCalculate.map((item) => ({
    label: `${item}`,
    value: `${item}`,
  }));
};

export const YearDropdownOptions = getYearsOptions();

export const TradingType: OptionType[] = [
  {
    label: 'Fixed',
    value: 'Fixed',
  },
  {
    label: 'Include',
    value: 'Include',
  },
];

export const PlayerPositionOptions: OptionType[] = [
  {
    label: 'Key Def',
    value: 'keydef',
  },
  {
    label: 'Gen Def',
    value: 'Gendef',
  },
  {
    label: 'Ruck',
    value: 'Ruck',
  },
  {
    label: 'Mid',
    value: 'Mid',
  },
  {
    label: 'Wing',
    value: 'Wing',
  },
  {
    label: 'Gen Fwd',
    value: 'Genfwd',
  },
  {
    label: 'Key Fwd',
    value: 'Keyfwd',
  },
];

export const PreferredFootOption: OptionType[] = [
  {
    label: 'Right',
    value: 'right',
  },
  {
    label: 'Left',
    value: 'left',
  },
];

export const PermissionOption: OptionType[] = [
  {
    label: 'External User',
    value: 'is_super',
  },
  {
    label: 'Internal User',
    value: 'is_staff',
  },
];

export const ContractCategoryOption: OptionType[] = [
  {
    label: 'Others',
    value: 'other',
  },
];

export const TeamFlagOptions = [
  {
    id: 1,
    teamName: 'Adelaide Crows',
    flagIcon: '/assets/Adelaide_Crows.png',
    shortName: 'AFC',
  },
  {
    id: 2,
    teamName: 'Brisbane Lions',
    flagIcon: '/assets/Brisbane_Lions.png',
    shortName: 'BFC',
  },
  {
    id: 3,
    teamName: 'Carlton',
    flagIcon: '/assets/Carlton.png',
    shortName: 'CARL',
  },
  {
    id: 4,
    teamName: 'Collingwood',
    flagIcon: '/assets/Collingwood.png',
    shortName: 'COLL',
  },
  {
    id: 5,
    teamName: 'Essendon',
    flagIcon: '/assets/Essendon.png',
    shortName: 'ESS',
  },
  {
    id: 6,
    teamName: 'Fremantle',
    flagIcon: '/assets/Fremantle.png',
    shortName: 'FRE',
  },
  {
    id: 7,
    teamName: 'Geelong Cats',
    flagIcon: '/assets/Geelong_Cats.png',
    shortName: 'GEEL',
  },
  {
    id: 8,
    teamName: 'Gold Coast Suns',
    flagIcon: '/assets/Gold_Coast_Suns.png',
    shortName: 'GCS',
  },
  {
    id: 9,
    teamName: 'GWS Giants',
    flagIcon: '/assets/GWS_Giants.png',
    shortName: 'GWS',
  },
  {
    id: 10,
    teamName: 'Hawthorn',
    flagIcon: '/assets/Hawthorn.png',
    shortName: 'HAW',
  },
  {
    id: 11,
    teamName: 'Melbourne',
    flagIcon: '/assets/Melbourne.png',
    shortName: 'MEL',
  },
  {
    id: 12,
    teamName: 'North Melbourne',
    flagIcon: '/assets/North_Melbourne.png',
    shortName: 'NM',
  },
  {
    id: 13,
    teamName: 'Port Adelaide',
    flagIcon: '/assets/Port_Adelaide.png',
    shortName: 'PA',
  },
  {
    id: 14,
    teamName: 'Richmond',
    flagIcon: '/assets/Richmond.png',
    shortName: 'RICH',
  },
  {
    id: 15,
    teamName: 'St Kilda',
    flagIcon: '/assets/St_Kilda.png',
    shortName: 'STK',
  },
  {
    id: 16,
    teamName: 'Sydney',
    flagIcon: '/assets/Sydney_Swans.png',
    shortName: 'SYD',
  },
  {
    id: 17,
    teamName: 'West Coast Eagles',
    flagIcon: '/assets/West_Coast_Eagles.png',
    shortName: 'WCE',
  },
  {
    id: 18,
    teamName: 'Western Bulldogs',
    flagIcon: '/assets/Western_Bulldogs.png',
    shortName: 'WB',
  },
];

export const PlayerDatabasePositionOptions: OptionType[] = [
  {
    label: 'Key Def',
    value: 'keyDef',
  },
  {
    label: 'Gen Def',
    value: 'Def',
  },
  {
    label: 'Ruck',
    value: 'Ruck',
  },
  {
    label: 'Mid',
    value: 'Mid',
  },
  {
    label: 'Wing',
    value: 'Wing',
  },
  {
    label: 'Gen Fwd',
    value: 'Fwd',
  },
  {
    label: 'Key Fwd',
    value: 'KeyFwd',
  },
];

export const TradeStatusOption: OptionType[] = [
  {
    label: 'Pending',
    value: TradeStatus.PENDING,
  },
  {
    label: 'Accepted',
    value: TradeStatus.APPROVED,
  },
  {
    label: 'Declined',
    value: TradeStatus.DECLINED,
  },
  {
    label: 'All',
    value: TradeStatus.ALL,
  },
];

export const SportingCodeOption: OptionType[] = [
  {
    label: 'AFL',
    value: 'AFL',
  },
];
