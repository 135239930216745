import {
  MdFeaturedPlayList,
  MdOutlineCompare,
  MdOutlineDashboardCustomize,
  MdOutlineSignalWifiStatusbar4Bar,
} from 'react-icons/md';
import { RiDraftFill } from 'react-icons/ri';
import { SiGoogleanalytics } from 'react-icons/si';
import { FaClipboard, FaDatabase } from 'react-icons/fa';
import { routeUrl } from '../../route/Url';
import { SideBarOptionType } from './type';
import { GiClubs } from 'react-icons/gi';
import { HiDocumentReport } from 'react-icons/hi';
import { GoHomeFill, GoOrganization } from 'react-icons/go';
import { IoIosListBox } from 'react-icons/io';
import { FaChartArea } from 'react-icons/fa6';
import { SiGoogletagmanager } from 'react-icons/si';
import { FiUsers } from 'react-icons/fi';
import { ORGANISATION_FLAG, PERMISSIONS_FLAG, USERS_FLAG } from '../../constant/SiteConstant';

const checkMenuToShowOrNot = (paramsKey: string) => {
  const searchParams = new URLSearchParams(window.location.search);
  return !!(searchParams.get(paramsKey) && searchParams.get(paramsKey) === '1');
};

export const sideMenuOptions = (isStaff: boolean): SideBarOptionType[] => [
  {
    menuLabel: '',
    hide: true,
    id: 'home',
    subMenu: [
      {
        id: 'home',
        label: 'Dashboard',
        toShow: false,
        icon: <MdOutlineDashboardCustomize />,
        url: routeUrl.dashboard,
      },
    ],
  },
  {
    menuLabel: 'Draft',
    id: 'smart_draft',
    subMenu: [
      {
        id: 'home',
        label: 'Draft Summary',
        toShow: true,
        icon: <MdOutlineDashboardCustomize />,
        url: routeUrl.dashboard,
      },
      {
        id: 'draft_pick',
        label: 'Draft Picks',
        toShow: true,
        icon: <RiDraftFill />,
        url: routeUrl.draftPick,
      },
      {
        id: 'trade_analysis',
        label: 'Trade Analysis',
        toShow: true,
        icon: <SiGoogleanalytics />,
        url: routeUrl.tradeAnalysis,
      },
      {
        id: 'scenario_planner',
        label: 'Scenario Planner',
        toShow: true,
        icon: <MdOutlineCompare />,
        url: routeUrl.scenarioPlanning,
      },

      {
        id: 'project_list',
        label: 'Project List',
        toShow: true,
        icon: <MdFeaturedPlayList />,
        url: routeUrl.projectList,
      },
    ],
  },
  {
    id: 'smart_player',
    menuLabel: 'Player',
    subMenu: [
      {
        id: 'players_home',
        label: 'Players Home',
        toShow: false,
        icon: <GoHomeFill />,
        url: routeUrl.playerHome,
      },
      {
        id: 'player_reports',
        label: 'Player Reports',
        toShow: false,
        icon: <HiDocumentReport />,
        url: routeUrl.playerReports,
      },
      {
        id: 'draft_board',
        label: 'Draft Board',
        toShow: true,
        icon: <FaClipboard />,
        url: routeUrl.draftBoard,
      },
      {
        id: 'player_ranking',
        label: 'Player Ranking',
        toShow: true,
        icon: <FaClipboard />,
        url: routeUrl.playerRanking,
      },
      {
        id: 'player_database',
        label: 'Player Database',
        toShow: true,
        icon: <FaDatabase />,
        url: routeUrl.playerDatabase,
      },
      {
        id: 'player_manager',
        label: 'Player Manager',
        toShow: true,
        icon: <SiGoogletagmanager />,
        url: routeUrl.playerManager,
      },
    ],
  },
  {
    id: 'smart_list',
    menuLabel: 'List',
    hide: false,
    subMenu: [
      {
        id: 'club_summary',
        label: 'Club Summary',
        toShow: true,
        icon: <GiClubs />,
        url: routeUrl.smartList,
      },
      {
        id: 'contract_status',
        label: 'Contract Status',
        toShow: false,
        icon: <MdOutlineSignalWifiStatusbar4Bar />,
        url: routeUrl.contractStatus,
      },
      {
        id: 'tpp',
        label: 'TPP',
        toShow: false,
        icon: <FaChartArea />,
        url: routeUrl.tpp,
      },
      {
        id: 'list_projection',
        label: 'List Projection',
        toShow: false,
        icon: <IoIosListBox />,
        url: routeUrl.listProjection,
      },
    ],
  },
  {
    id: 'settings',
    menuLabel: 'Settings',
    hide: !(
      isStaff ||
      checkMenuToShowOrNot(ORGANISATION_FLAG) ||
      checkMenuToShowOrNot(USERS_FLAG) ||
      checkMenuToShowOrNot(PERMISSIONS_FLAG)
    ),
    subMenu: [
      {
        id: 'organisation',
        label: 'Organisations',
        toShow: isStaff || checkMenuToShowOrNot(ORGANISATION_FLAG),
        icon: <GoOrganization />,
        url: routeUrl.organisation,
      },
      {
        id: 'users',
        label: 'Users',
        toShow: isStaff || checkMenuToShowOrNot(USERS_FLAG),
        icon: <FiUsers />,
        url: '/users',
      },
      {
        id: 'permissions',
        label: 'Permissions',
        toShow: isStaff || checkMenuToShowOrNot(PERMISSIONS_FLAG),
        icon: <FaClipboard />,
        url: '/permissions',
      },
    ],
  },
];
