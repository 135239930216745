import { configureStore, combineReducers, ThunkAction, Action } from '@reduxjs/toolkit';
import authReducer from './auth';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import storage from 'redux-persist/lib/storage';
import persistReducer from 'redux-persist/es/persistReducer';
import { smartDraftApis } from './service';
import siteStatusReducer from './header';
import projectListReducer from './projects';
import { uploadCsvService } from './service/uploadcsvservice';
import { scenarioService } from './service/scenarioservice';
import { draftBoardService } from './service/draftboardservice';
import { passPickServiceApis } from './service/passpickservice';
import { organisationServiceApi } from './service/organisationservice';
import { playerDatabaseServiceApi } from './service/playerdatabaseservice';
import { clubSummaryServiceApi } from './service/clubsummaryservice';

const persistConfig = {
  key: 'auth',
  storage,
  blacklist: [''],
};

const persistHeaderConfig = {
  key: 'header',
  storage,
  blacklist: ['selectedTransactionModal', 'uploadCsvTransactionTypeModal'],
};

const persistedAuthReducer = persistReducer(persistConfig, authReducer);
const siteStatusAuthReducer = persistReducer(persistHeaderConfig, siteStatusReducer);

const rootReducer = combineReducers({
  auth: persistedAuthReducer,
  siteStatus: siteStatusAuthReducer,
  projectList: projectListReducer,
  [smartDraftApis.reducerPath]: smartDraftApis.reducer,
  [uploadCsvService.reducerPath]: uploadCsvService.reducer,
  [scenarioService.reducerPath]: scenarioService.reducer,
  [draftBoardService.reducerPath]: draftBoardService.reducer,
  [passPickServiceApis.reducerPath]: passPickServiceApis.reducer,
  [organisationServiceApi.reducerPath]: organisationServiceApi.reducer,
  [playerDatabaseServiceApi.reducerPath]: playerDatabaseServiceApi.reducer,
  [clubSummaryServiceApi.reducerPath]: clubSummaryServiceApi.reducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      smartDraftApis.middleware,
      uploadCsvService.middleware,
      scenarioService.middleware,
      draftBoardService.middleware,
      passPickServiceApis.middleware,
      organisationServiceApi.middleware,
      playerDatabaseServiceApi.middleware,
      clubSummaryServiceApi.middleware,
    ]),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
