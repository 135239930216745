/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { FieldsType, FilterOptionType, PLAYER_INFO_TAB, TimelineOfEntryType } from './type';
import { useCurrentOrganisation } from '../../store/header/selector';
import { useAppSelector } from '../../store/hooks';
import {
  useCreateTimelineEntryMutation,
  useDeletePlayerTimelineElegibilityMutation,
  useGetTimelineEntryFieldQuery,
  useLazyGetPlayerAccoladeQuery,
  useLazyGetPlayerBioQuery,
  useLazyGetPlayerTimelineQuery,
  useUpdateTimelineEntryMutation,
} from '../../store/service/playerdatabaseservice';
import { useParams } from 'react-router-dom';
import { transformAccoladeData } from '../playerdatabase/util';
import {
  filterInitialValue,
  transformBioDetails,
  transformFieldDetails,
  transformSelectFormOption,
  transformTimelineData,
} from './util';
import { useForm, useWatch } from 'react-hook-form';
import { format } from 'date-fns';

export const usePlayerMoreInfo = () => {
  const [selectedTab, setSelectedTab] = useState<PLAYER_INFO_TAB>(PLAYER_INFO_TAB.TIMELINE);

  return {
    selectedTab,
    setSelectedTab,
  };
};

export const usePlayerAward = () => {
  const currentOrgId = useAppSelector(useCurrentOrganisation);
  const [fetchAccolade, { isLoading, data }] = useLazyGetPlayerAccoladeQuery();
  const { playerId } = useParams();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    playerId &&
      fetchAccolade({
        orgId: currentOrgId,
        playerId,
      });
  };

  const trasnformData = useMemo(() => transformAccoladeData(data), [data]);
  return {
    data: trasnformData,
    isLoading,
  };
};

export const usePlayerTimeline = () => {
  const currentOrgId = useAppSelector(useCurrentOrganisation);
  const [fetchPlayerTimeline, { isLoading, data }] = useLazyGetPlayerTimelineQuery();
  const { playerId } = useParams();
  const [openModalForEntry, setOpenModalForEntry] = useState(false);
  const [selectedTimelineForDelete, setSelectedTimelineForDelete] = useState<string | null>(null);
  const [selectedTimelineForEdit, setSelectedTimelineForEdit] = useState<
    TimelineOfEntryType | undefined
  >(undefined);
  const [selectedFilter, setSelectedFilter] = useState<FilterOptionType>({
    createdAt: {
      endDate: null,
      startDate: null,
    },
    formType: '',
    open: false,
    updatedAt: {
      endDate: null,
      startDate: null,
    },
  });

  const [deleteTimeline, { isLoading: deleteLoading }] =
    useDeletePlayerTimelineElegibilityMutation();
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    playerId &&
      fetchPlayerTimeline({
        orgId: currentOrgId,
        playerId,
      }).then(() => {
        setOpenModalForEntry(false);
        setSelectedTimelineForEdit(undefined);
      });
  };

  const transformData = useMemo(() => transformTimelineData(data), [data]);

  const handleDelete = () => {
    selectedTimelineForDelete &&
      deleteTimeline({
        formId: selectedTimelineForDelete,
        orgId: currentOrgId,
      }).then((res: any) => {
        if (!res.err) {
          setSelectedTimelineForDelete(null);
          fetchData();
        }
      });
  };
  return {
    data: transformData,
    isLoading,
    setOpenModalForEntry,
    openModalForEntry,
    refreshList: fetchData,
    selectedTimelineForDelete,
    setSelectedTimelineForDelete,
    selectedTimelineForEdit,
    setSelectedTimelineForEdit,
    handleDelete,
    deleteLoading,
    setSelectedFilter,
    selectedFilter,
  };
};

export const usePlayerBio = () => {
  const [fetchPlayerBio, { isLoading, data }] = useLazyGetPlayerBioQuery();
  const { playerId } = useParams();
  const currentOrgId = useAppSelector(useCurrentOrganisation);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    playerId &&
      fetchPlayerBio({
        orgId: currentOrgId,
        playerId,
      });
  };

  return {
    bioDetails: transformBioDetails(data),
    isLoading,
  };
};

export const useAddTimelineEntry = (
  refreshList: () => void,
  selectedTimelineForEdit?: TimelineOfEntryType,
) => {
  const { data } = useGetTimelineEntryFieldQuery('');
  const [selectedForm, setSelectedForm] = useState(selectedTimelineForEdit?.name || '');
  const [selectedFormFieldInfo, setSelectedFormField] = useState<FieldsType | undefined>();
  const [createTimeline, { isLoading: createTimeLineLoading }] = useCreateTimelineEntryMutation();
  const [updateTimeLine, { isLoading: updateTimeLineLoading }] = useUpdateTimelineEntryMutation();

  const transformFieldInfo: FieldsType[] = useMemo(
    () => transformFieldDetails(data, selectedTimelineForEdit),
    [data, selectedTimelineForEdit],
  );

  const { playerId } = useParams();
  const currentOrgId = useAppSelector(useCurrentOrganisation);

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    if (selectedTimelineForEdit) {
      loadForm();
    }
  }, [selectedTimelineForEdit, transformFieldInfo]);

  const loadForm = () => {
    const loadField = transformFieldInfo.find((item) => item.name === selectedForm);
    setSelectedFormField(loadField);
    let fieldDetails: Record<string, string | number | null> = {};

    loadField?.fields.forEach((field) => {
      fieldDetails[field.fieldId] = field.value;
    });
    reset(fieldDetails);
  };

  const onSubmit = (value: any) => {
    const payload = Object.entries(value).map((item: any) => ({
      id: item[0],
      value: typeof item[1] === 'object' ? format(new Date(item[1]), 'yyyy-MM-dd') : item[1],
    }));
    if (selectedTimelineForEdit) {
      updateTimeLine({
        data: payload,
        formId: selectedTimelineForEdit.id.toString(),
        orgId: currentOrgId,
      }).then((res: any) => {
        if (!res.error) {
          refreshList();
        }
      });
      return;
    }
    playerId &&
      createTimeline({
        data: payload,
        formName: selectedForm,
        orgId: currentOrgId,
        playerId: playerId,
      }).then((res: any) => {
        if (!res.error) {
          refreshList();
          return;
        }
      });
  };
  return {
    data: transformFieldInfo,
    isLoading: createTimeLineLoading || updateTimeLineLoading,
    selectedForm,
    setSelectedForm,
    loadForm,
    selectedFormFieldInfo,
    register,
    errors,
    handleSubmit,
    onSubmit,
    control,
  };
};

export const useTimelineFilterDrawer = (initialData: FilterOptionType) => {
  const { control, register, handleSubmit } = useForm({
    defaultValues: filterInitialValue(initialData),
  });
  const { data } = useGetTimelineEntryFieldQuery('');

  const formDataOpt = useMemo(() => transformSelectFormOption(data), [data]);
  const formType = useWatch({
    control,
    name: 'formType',
  });

  return {
    control,
    register,
    handleSubmit,
    formType,
    data,
    formDataOpt,
  };
};
