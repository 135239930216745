/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import {
  AccoladeAddUpdateType,
  AccoladeType,
  ContractAddUpdateType,
  ContractType,
  ElegibilityAddUpdateType,
  ElegibilityType,
  OverlappingSessionType,
  PlayerDatabaseType,
  PlayerSelectedTabEnum,
} from './type';
import {
  useCreateNewAccoladePlayerMutation,
  useCreateNewContractPlayerMutation,
  useCreateNewElegibilityMutation,
  useCreateNewPlayerMutation,
  useDeletePlayerAccoladeMutation,
  useDeletePlayerContractMutation,
  useDeletePlayerElegibilityMutation,
  useDeletePlayerMutation,
  useGetAccoladeTypeQuery,
  useGetCategoryContractOptionQuery,
  useGetPlayerElegibilityOptionsQuery,
  useGetPlayerManagerQuery,
  useGetPlayerStateQuery,
  useLazyGetPlayerAccoladeQuery,
  useLazyGetPlayerContractQuery,
  useLazyGetPlayerDatabaseQuery,
  useLazyGetPlayerElegibilityQuery,
  useUpdatePlayerAccoladeMutation,
  useUpdatePlayerContractMutation,
  useUpdatePlayerElegibilityMutation,
  useUpdatePlayerMutation,
} from '../../store/service/playerdatabaseservice';
import { useAppSelector } from '../../store/hooks';
import { useCurrentOrganisation } from '../../store/header/selector';
import { useForm, useWatch } from 'react-hook-form';
import {
  accoladeInitialValue,
  accoladeValidationSchema,
  contractInitialValue,
  contractValidationSchema,
  elegibilityInitialValue,
  elegibilityValidationSchema,
  playerInitialValue,
  playerValidationSchema,
  transformAccoladeData,
  transformAccoladeOption,
  transformCategoryOption,
  transformContractData,
  transformElegibilityData,
  transformEligibilityOptions,
  transformOptionState,
  transformPlayerList,
  transformPlayerManagerData,
} from './util';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { PLAYER_MANAGER_DATA } from '../../data/dummyData';

export const usePlayerDatabase = () => {
  const [fetchData, { data, isLoading }] = useLazyGetPlayerDatabaseQuery();
  const [createPlayerModal, setCreatePlayerModal] = useState(false);
  const [selectedPlayerForEdit, setSelectedPlayerForEdit] = useState<
    PlayerDatabaseType | undefined
  >(undefined);
  const currentOrgId = useAppSelector(useCurrentOrganisation);
  const [selectedPlayerForDelete, setSelectedPlayerForDelete] = useState<string | null>(null);
  const [deletePlayer, { isLoading: deleteLoading }] = useDeletePlayerMutation();
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    fetchPlayerData();
  }, [currentOrgId]);

  const fetchPlayerData = () => {
    if (currentOrgId !== '') {
      fetchData(currentOrgId);
      return;
    }
    toast.error('Please select organisation');
  };

  const handleDelete = () => {
    selectedPlayerForDelete &&
      deletePlayer({
        orgId: currentOrgId,
        playerId: selectedPlayerForDelete,
      }).then((res: any) => {
        if (!res.error) {
          fetchPlayerData();
          setSelectedPlayerForDelete(null);
          return;
        }
      });
  };
  return {
    createPlayerModal,
    setCreatePlayerModal,
    selectedPlayerForEdit,
    setSelectedPlayerForEdit,
    refreshData: fetchPlayerData,
    selectedPlayerForDelete,
    setSelectedPlayerForDelete,
    isLoading,
    handleDelete,
    deleteLoading,
    playerList: transformPlayerList(data),
    searchText,
    setSearchText,
  };
};

export const useAddUpdatePlayer = (
  handleClose: () => void,
  refreshList: () => void,
  playerData?: PlayerDatabaseType,
  initialTab?: PlayerSelectedTabEnum,
) => {
  const currentOrgId = useAppSelector(useCurrentOrganisation);
  const [selectedTab, setSelectedTab] = useState<PlayerSelectedTabEnum>(
    initialTab || PlayerSelectedTabEnum.BIO,
  );

  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: playerInitialValue(playerData),
    resolver: yupResolver(playerValidationSchema),
  });

  const [createData, { isLoading }] = useCreateNewPlayerMutation();
  const [updateData, { isLoading: updatePlayerLoading }] = useUpdatePlayerMutation();

  const onSubmit = (value: PlayerDatabaseType) => {
    if (playerData) {
      updateData({
        data: value,
        orgId: currentOrgId,
      }).then((res: any) => {
        if (!res.error) {
          toast.success('Updated Successfully');
          refreshList();
          handleClose();
          return;
        }
      });
      return;
    }

    createData({
      data: value,
      orgId: currentOrgId,
    }).then((res: any) => {
      if (!res.error) {
        refreshList();
        handleClose();
        return;
      }
    });
  };

  return {
    handleSubmit,
    register,
    control,
    errors,
    onSubmit,
    isLoading: isLoading || updatePlayerLoading,
    selectedTab,
    setSelectedTab,
    setValue,
  };
};

export const useContract = (playerId: string) => {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedContractForEdit, setSelecetedContractForEdit] = useState<ContractType | undefined>(
    undefined,
  );
  const [fetchContract, { isLoading, data }] = useLazyGetPlayerContractQuery();
  const currentOrgId = useAppSelector(useCurrentOrganisation);
  const [deleteContract, { isLoading: deleteLoading }] = useDeletePlayerContractMutation();
  const [selectedContractForDelete, setSelectedContractForDelete] = useState<
    ContractType | undefined
  >(undefined);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setOpenAddModal(false);
    setSelecetedContractForEdit(undefined);
    fetchContract({
      orgId: currentOrgId,
      playerId,
    });
  };

  const handleContractDelete = () => {
    selectedContractForDelete &&
      deleteContract({
        roasterAllocationId: selectedContractForDelete.id,
        orgId: currentOrgId,
      }).then((res: any) => {
        if (!res.error) {
          fetchData();
          setSelectedContractForDelete(undefined);
        }
      });
  };

  return {
    data: transformContractData(data),
    loading: isLoading,
    setOpenAddModal,
    openAddModal,
    selectedContractForEdit,
    setSelecetedContractForEdit,
    refreshList: fetchData,
    handleContractDelete,
    setSelectedContractForDelete,
    deleteLoading,
    selectedContractForDelete,
  };
};

type useAddUpdateContractTypes = {
  playerId: string;
  refreshList: () => void;
  seasonList: OverlappingSessionType[];
  contractInfo?: ContractType;
  selectedTeam?: string;
  selectedStartSeason?: string;
};
export const useAddUpdateContract = ({
  playerId,
  refreshList,
  contractInfo,
  selectedTeam,
  selectedStartSeason,
  seasonList,
}: useAddUpdateContractTypes) => {
  const currentOrgId = useAppSelector(useCurrentOrganisation);
  const [createNewContract, { isLoading }] = useCreateNewContractPlayerMutation();
  const [updateContract, { isLoading: updateLoading }] = useUpdatePlayerContractMutation();
  const { data: categoryOpt } = useGetCategoryContractOptionQuery('');

  const catOpt = useMemo(() => transformCategoryOption(categoryOpt), [categoryOpt]);

  const filteredSeasonList = useMemo(() => {
    return seasonList.filter(
      (item) =>
        item.endSeason !== contractInfo?.endSeason.toString() &&
        item.startSeason !== contractInfo?.startSeason.toString(),
    );
  }, [contractInfo, seasonList]);

  const {
    handleSubmit,
    register,
    control,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: contractInitialValue(
      contractInfo
        ? contractInfo
        : {
            contractType: '',
            endDate: '',
            id: '',
            signingDate: '',
            startDate: selectedStartSeason ? `01/01/${selectedStartSeason}` : '',
            team: selectedTeam || '',
            startSeason: selectedStartSeason ? `01/01/${selectedStartSeason}` : '',
            endSeason: '',
            contractEndReason: '',
            signingAnnoucementSource: '',
          },
    ),
    resolver: yupResolver(contractValidationSchema(filteredSeasonList)),
  });

  const startSeason = useWatch({
    control,
    name: 'startSeason',
  });
  const startDate = useWatch({
    control,
    name: 'startDate',
  });
  const endDate = useWatch({
    control,
    name: 'endDate',
  });
  const endSeason = useWatch({
    control,
    name: 'endSeason',
  });

  useEffect(() => {
    if (!new Date(endDate).getFullYear()) {
      const currentEndYear = new Date(endSeason).getFullYear();
      setValue('endDate', new Date(`10/31/${currentEndYear}`).toString());
    }
  }, [endSeason, endDate]);

  useEffect(() => {
    if (!new Date(startDate).getFullYear()) {
      const currentYear = new Date(startSeason).getFullYear();
      setValue('startDate', `1/1/${currentYear}`);
    }
  }, [startSeason, startDate]);

  const contractType = useWatch({
    control,
    name: 'contractType',
  });
  const team = useWatch({
    control,
    name: 'team',
  });

  const onSubmit = (value: ContractAddUpdateType) => {
    if (contractInfo) {
      updateContract({
        contractId: contractInfo.id,
        data: value,
        orgId: currentOrgId,
      }).then((res: any) => {
        if (!res.error) {
          refreshList();
        }
      });
      return;
    }
    createNewContract({
      data: value,
      orgId: currentOrgId,
      playerId,
    }).then((res: any) => {
      if (!res.error) {
        refreshList();
      }
    });
  };
  return {
    contractType,
    team,
    control,
    handleSubmit,
    onSubmit,
    register,
    errors,
    isLoading: isLoading || updateLoading,
    setError,
    clearErrors,
    catOpt,
  };
};
export const useAccolade = (playerId: string) => {
  const currentOrgId = useAppSelector(useCurrentOrganisation);
  const [fetchAccolade, { isLoading, data }] = useLazyGetPlayerAccoladeQuery();
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedAccoladeForEdit, setSelecetedAccoladeForEdit] = useState<AccoladeType | undefined>(
    undefined,
  );
  const [selectedAccoladeForDelete, setSelectedAccoladeForDelete] = useState<
    AccoladeType | undefined
  >(undefined);
  const [deleteAccord, { isLoading: deleteLoading }] = useDeletePlayerAccoladeMutation();
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setOpenAddModal(false);
    setSelecetedAccoladeForEdit(undefined);
    fetchAccolade({
      orgId: currentOrgId,
      playerId,
    });
  };

  const handelAccoladeDelete = () => {
    selectedAccoladeForDelete &&
      deleteAccord({
        accId: selectedAccoladeForDelete.id,
        orgId: currentOrgId,
      }).then((res: any) => {
        if (!res.error) {
          fetchData();
          setSelectedAccoladeForDelete(undefined);
        }
      });
  };
  return {
    data: transformAccoladeData(data),
    loading: isLoading,
    openAddModal,
    setOpenAddModal,
    selectedAccoladeForEdit,
    setSelecetedAccoladeForEdit,
    refreshList: fetchData,
    setSelectedAccoladeForDelete,
    selectedAccoladeForDelete,
    handelAccoladeDelete,
    deleteLoading,
  };
};

export const usePlayerManager = () => {
  const { isLoading, data } = useGetPlayerManagerQuery('12');

  return {
    data: transformPlayerManagerData(PLAYER_MANAGER_DATA || data),
    loading: isLoading,
  };
};

export const useElegibility = (playerId: string) => {
  const [fetchElegibility, { data, isLoading }] = useLazyGetPlayerElegibilityQuery();
  const currentOrgId = useAppSelector(useCurrentOrganisation);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedElegibilityForEdit, setSelectedElegibilityForEdit] = useState<
    ElegibilityType | undefined
  >(undefined);
  const [selectedElegibilityForDelete, setSelectedElegibilityForDelete] = useState<
    ElegibilityType | undefined
  >(undefined);
  const [deleteElegibility, { isLoading: deleteLoading }] = useDeletePlayerElegibilityMutation();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    fetchElegibility({
      orgId: currentOrgId,
      playerId: playerId,
    }).then(() => {
      setOpenAddModal(false);
      setSelectedElegibilityForEdit(undefined);
    });
  };

  const handelElegibilityDelete = () => {
    selectedElegibilityForDelete &&
      deleteElegibility({
        playerElegibilityId: selectedElegibilityForDelete.id,
        orgId: currentOrgId,
      }).then((res: any) => {
        if (!res.error) {
          fetchData();
          setSelectedElegibilityForDelete(undefined);
        }
      });
  };

  return {
    data: transformElegibilityData(data),
    loading: isLoading,
    openAddModal,
    setOpenAddModal,
    selectedElegibilityForEdit,
    setSelectedElegibilityForEdit,
    refreshList: fetchData,
    handelElegibilityDelete,
    deleteLoading,
    setSelectedElegibilityForDelete,
    selectedElegibilityForDelete,
  };
};

export const useAddUpdateAccolade = (
  playerId: string,
  refreshList: () => void,
  accoladeInfo?: AccoladeType,
) => {
  const currentOrgId = useAppSelector(useCurrentOrganisation);
  const [createNewAccolade, { isLoading }] = useCreateNewAccoladePlayerMutation();
  const [updateAccolade, { isLoading: updateLoading }] = useUpdatePlayerAccoladeMutation();
  const { data: accoladeData } = useGetAccoladeTypeQuery('');

  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: accoladeInitialValue(accoladeInfo),
    resolver: yupResolver(accoladeValidationSchema),
  });

  const accoladeName = useWatch({
    control,
    name: 'accoladeName',
  });

  const accoladeCategory = useWatch({
    control,
    name: 'accoladeCategory',
  });

  const accoladeSeason = useWatch({
    control,
    name: 'season',
  });
  const onSubmit = (value: AccoladeAddUpdateType) => {
    if (accoladeInfo) {
      updateAccolade({
        accId: accoladeInfo.id,
        data: value,
        orgId: currentOrgId,
      }).then((res: any) => {
        if (!res.error) {
          refreshList();
        }
      });
      return;
    }
    createNewAccolade({
      data: value,
      orgId: currentOrgId,
      playerId,
    }).then((res: any) => {
      if (!res.error) {
        refreshList();
      }
    });
  };
  const accoladeCategoryOptions = useMemo(
    () => transformAccoladeOption(accoladeData).category,
    [accoladeData],
  );
  const accoladeNameOptions = useMemo(
    () => (transformAccoladeOption(accoladeData).name as any)?.[accoladeCategory] || [],
    [accoladeCategory, accoladeData],
  );

  return {
    handleSubmit,
    register,
    control,
    errors,
    onSubmit,
    isLoading,
    updateLoading,
    nameOptions: accoladeNameOptions,
    categoryOptions: accoladeCategoryOptions,
    accoladeCategory,
    accoladeName,
    setValue,
    accoladeSeason,
  };
};

export const useAddUpdateElegibility = (
  playerId: string,
  refreshList: () => void,
  elegibilityInfo?: ElegibilityType,
) => {
  const currentOrgId = useAppSelector(useCurrentOrganisation);
  const [createNewElegibility, { isLoading }] = useCreateNewElegibilityMutation();
  const [updateElegibility, { isLoading: updateLoading }] = useUpdatePlayerElegibilityMutation();
  const { data: elegibilityOptions } = useGetPlayerElegibilityOptionsQuery('');

  const elegibilitOptionsList = useMemo(
    () => transformEligibilityOptions(elegibilityOptions),
    [elegibilityOptions],
  );

  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: elegibilityInitialValue(elegibilityInfo),
    resolver: yupResolver(elegibilityValidationSchema),
  });

  const elegibilityType = useWatch({
    control,
    name: 'elegibilityType',
  });

  const elegiblityStatus = useWatch({
    control,
    name: 'elegiblityStatus',
  });

  const elegibilitySeason = useWatch({
    control,
    name: 'season',
  });
  const onSubmit = (value: ElegibilityAddUpdateType) => {
    if (elegibilityInfo) {
      updateElegibility({
        elegibilityId: elegibilityInfo.id,
        data: value,
        orgId: currentOrgId,
      }).then((res: any) => {
        if (!res.error) {
          refreshList();
        }
      });
      return;
    }
    createNewElegibility({
      data: value,
      orgId: currentOrgId,
      playerId,
    }).then((res: any) => {
      if (!res.error) {
        refreshList();
      }
    });
  };

  return {
    handleSubmit,
    register,
    control,
    errors,
    onSubmit,
    isLoading,
    updateLoading,
    categoryOptions: [],
    setValue,
    elegibilityType,
    elegibilitySeason,
    elegiblityStatus,
    elegibilitOptionsList,
  };
};

export const useBioUpdate = () => {
  const { data } = useGetPlayerStateQuery('');
  const stateOptions = useMemo(() => transformOptionState(data), [data]);
  return {
    stateOptions,
  };
};
