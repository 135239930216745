import { Grid } from '@mui/material';
import PlayerInfoSection from './component/PlayerInfoSection';
import PlayerMoreInfo from './component/PlayerMoreInfo';
import { usePlayerBio } from './hook';
import LoadingWrapper from '../../common/component/LoadingWrapper';

const PlayerInfo = () => {
  const { bioDetails, isLoading } = usePlayerBio();
  return (
    <Grid container>
      <Grid item xs={12} borderRadius={'5px'} bgcolor={'#8b0042'}>
        <LoadingWrapper isLoading={isLoading}>
          {bioDetails && <PlayerInfoSection bioDetails={bioDetails} />}
        </LoadingWrapper>
      </Grid>
      <Grid item xs={12}>
        <PlayerMoreInfo />
      </Grid>
    </Grid>
  );
};

export default PlayerInfo;
