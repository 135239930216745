import { format } from 'date-fns';
import {
  AccoladeAddUpdateType,
  ContractAddUpdateType,
  ElegibilityAddUpdateType,
  PlayerDatabaseType,
} from '../../../container/playerdatabase/type';

export const reverseTransformPlayerData = (data: PlayerDatabaseType): any => {
  return {
    first_name: data.firstName,
    preferred_first_name: data.preferredFirstName,
    last_name: data.lastName,
    preferred_last_name: data.preferredLastName,
    junior_team: data.juniorTeam,
    date_of_birth: data.dateOfBirth ? format(new Date(data.dateOfBirth), 'yyyy-MM-dd') : '',
    height: data.height,
    weight: data.weight,
    position: data.position,
    secondary_position: data.secondaryPosition,
    state: data.state,
    preferred_foot: data.preferredFoot,
  };
};

export const reversePlayerAccoladeData = (data: AccoladeAddUpdateType): any => {
  return {
    season: data.season,
    accolade_category: data.accoladeCategory,
    accolade_name: data.accoladeName,
  };
};

export const reversePlayerElegibilityData = (data: ElegibilityAddUpdateType): any => {
  return {
    season: data.season,
    eligibility_type: data.elegibilityType,
    eligibility_status: data.elegiblityStatus,
  };
};

export const reversePlayerContractData = (data: ContractAddUpdateType): any => {
  return {
    start_date: format(new Date(data.startDate), 'yyyy-MM-dd'),
    end_date: format(new Date(data.endDate), 'yyyy-MM-dd'),
    signing_date: format(new Date(data.signingDate), 'yyyy-MM-dd'),
    team: data.team,
    contract_type: data.contractType,
    start_season: format(new Date(data.startSeason), 'yyyy'),
    end_season: format(new Date(data.endSeason), 'yyyy'),
    signing_announcement_source: data.signingAnnoucementSource,
    contract_end_reason: data.contractEndReason,
  };
};
