import { FC, useState } from 'react';
import { Grid, Box } from '@mui/material';
import { StyledCard } from '../../../common/component/Card';
import { Header3 } from '../../../common/component/Text';
import { DragDropContext } from 'react-beautiful-dnd';
import { PlayerRankingType, UpdateRankingType } from '../type';
import { sortPlayerListByRank, transformToPlayerRankingData } from '../util';
import PlayerRankDatagrid from './PlayerRankDataGrid';
import PlayerList from './PlayerList';
import { moveElementToPosition } from '../util';
import { PlayerDatabaseType } from '../../playerdatabase/type';
import { differenceBy } from 'lodash';

type PlayerRankBoardTierProps = {
  list: PlayerRankingType[];
  isLoading: boolean;
  updatePlayerPos: (playerInfo: UpdateRankingType) => void;
  playerList: PlayerDatabaseType[];
};

const PlayerRankBoardTier: FC<PlayerRankBoardTierProps> = ({
  list,
  isLoading,
  updatePlayerPos,
  playerList,
}) => {
  const [tier1, setTier1] = useState<PlayerRankingType[]>(list.filter((item) => item.tier === 1));
  const [tier2, setTier2] = useState<PlayerRankingType[]>(
    sortPlayerListByRank(list.filter((item) => item.tier === 2)),
  );
  const [tier3, setTier3] = useState<PlayerRankingType[]>(
    sortPlayerListByRank(list.filter((item) => item.tier === 3)),
  );
  const [tier4, setTier4] = useState<PlayerRankingType[]>(
    sortPlayerListByRank(list.filter((item) => item.tier === 4)),
  );
  const [tier5, setTier5] = useState<PlayerRankingType[]>(
    sortPlayerListByRank(list.filter((item) => item.tier === 5)),
  );

  const tierList: Record<
    string,
    {
      list: PlayerRankingType[];
      setTier: any;
    }
  > = {
    tier1: {
      list: tier1,
      setTier: setTier1,
    },
    tier2: {
      list: tier2,
      setTier: setTier2,
    },
    tier3: {
      list: tier3,
      setTier: setTier3,
    },
    tier4: {
      list: tier4,
      setTier: setTier4,
    },
    tier5: {
      list: tier5,
      setTier: setTier5,
    },
  };

  const onDragEnd = (value: any) => {
    if (!value.draggableId) {
      return;
    }
    const item = list.find((item) => item.id.toString() === value.draggableId);
    let newList = [];
    if (!value?.source?.droppableId || !value?.destination?.droppableId) {
      return;
    }
    if (item) {
      if (value.source.droppableId !== value.destination.droppableId) {
        newList = tierList[value.source.droppableId].list.filter(
          (item) => item.id.toString() !== value.draggableId,
        );
        tierList[value.source.droppableId].setTier(newList);
      }
      const itemBelow = tierList[value.destination.droppableId].list[value.destination.index];
      const itemAbove = tierList[value.destination.droppableId].list[value.destination.index - 1];
      newList = moveElementToPosition(
        tierList[value.destination.droppableId].list,
        item,
        value.destination.index,
      );
      tierList[value.destination.droppableId].setTier(newList);
      updatePlayerPos({
        playerId: value.draggableId,
        ranking: itemBelow?.rank || itemAbove?.rank + 1,
        tier: value.destination.droppableId,
      });
      return;
    }
    const draggingPlayer = playerList.find(
      (item) => item.id.toString() === value.draggableId.toString(),
    );

    if (draggingPlayer) {
      const itemBelow = tierList[value.destination.droppableId].list[value.destination.index];
      const itemAbove = tierList[value.destination.droppableId].list[value.destination.index - 1];
      newList = moveElementToPosition(
        tierList[value.destination.droppableId].list,
        transformToPlayerRankingData(
          draggingPlayer,
          itemBelow?.rank || itemAbove?.rank + 1,
          value.destination.droppableId,
        ),
        value.destination.index,
      );
      updatePlayerPos({
        playerId: value.draggableId,
        ranking: itemBelow?.rank || itemAbove?.rank + 1,
        tier: value.destination.droppableId,
      });
    }
  };

  return (
    <Grid container>
      <DragDropContext onDragEnd={onDragEnd}>
        <Grid item xs={12}>
          <Grid container columnSpacing={'20px'}>
            <Grid item xs={10}>
              <Grid container style={{ pointerEvents: isLoading ? 'none' : 'all' }}>
                <Grid item xs={12}>
                  <Grid overflow={'auto'} gap={'30px'} display={'flex'}>
                    <StyledCard>
                      <Box width={'270px'}>
                        <Grid width={'100%'}>
                          <Header3 textsize={'16px'} textAlign={'center'}>
                            Tier 1
                          </Header3>
                        </Grid>
                        <Grid item xs={12}>
                          <PlayerRankDatagrid list={tier1} droppableId='tier1' />
                        </Grid>
                      </Box>
                    </StyledCard>
                    <StyledCard>
                      <Box width={'270px'}>
                        <Grid width={'100%'}>
                          <Header3 textsize={'16px'} textAlign={'center'}>
                            Tier 2
                          </Header3>
                        </Grid>
                        <Grid item xs={12}>
                          <PlayerRankDatagrid list={tier2} droppableId='tier2' />
                        </Grid>
                      </Box>
                    </StyledCard>
                    <StyledCard>
                      <Box width={'270px'}>
                        <Grid width={'100%'}>
                          <Header3 textsize={'16px'} textAlign={'center'}>
                            Tier 3
                          </Header3>
                        </Grid>
                        <Grid item xs={12}>
                          <PlayerRankDatagrid list={tier3} droppableId='tier3' />
                        </Grid>
                      </Box>
                    </StyledCard>
                    <StyledCard>
                      <Box width={'270px'}>
                        <Grid width={'100%'}>
                          <Header3 textsize={'16px'} textAlign={'center'}>
                            Tier 4
                          </Header3>
                        </Grid>
                        <Grid item xs={12}>
                          <PlayerRankDatagrid list={tier4} droppableId='tier4' />
                        </Grid>
                      </Box>
                    </StyledCard>
                    <StyledCard>
                      <Box width={'270px'}>
                        <Grid width={'100%'}>
                          <Header3 textsize={'16px'} textAlign={'center'}>
                            Tier 5
                          </Header3>
                        </Grid>
                        <Grid item xs={12}>
                          <PlayerRankDatagrid list={tier5} droppableId='tier5' />
                        </Grid>
                      </Box>
                    </StyledCard>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <PlayerList playerList={differenceBy(playerList, list, 'id')} />
            </Grid>
          </Grid>
        </Grid>
      </DragDropContext>
    </Grid>
  );
};

export default PlayerRankBoardTier;
