import { DialogActions, DialogContent, Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog/Dialog';
import Divider from '@mui/material/Divider/Divider';
import IconButton from '@mui/material/IconButton/IconButton';
import React, { FC } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { TEXT_COLOR_THEME } from '../../theme/color';
import { PrimaryButton, PrimaryTextButton } from './Button';
import { Header3 } from './Text';

type Props = {
  handleClose: () => void;
  children: JSX.Element;
  confirmButtonLabel?: string;
  closeButtonLabel?: string;
  saveLoading?: boolean;
  handleSubmit?: () => void;
  handleReactFormSubmit?: (callbackfn: any) => void;
  modalType?: 'react-hook' | 'simple';
  modalTitle?: string;
  modalSize?: 'sm' | 'md' | 'lg' | 'xl' | 'xs';
  hideClose?: boolean;
  disableButton?: boolean;
  hideDialogAction?: boolean;
  modalHeight?: string;
};

const CustomModal: FC<Props> = ({
  handleClose,
  children,
  confirmButtonLabel,
  closeButtonLabel,
  saveLoading,
  modalTitle,
  modalSize,
  handleSubmit,
  modalType = 'simple',
  handleReactFormSubmit,
  hideClose = false,
  disableButton = false,
  hideDialogAction = false,
  modalHeight,
}) => {
  const renderModalInfo = () => {
    return (
      <Grid container py={'10px'} px={'30px'}>
        <Grid item xs={12}>
          <Grid container alignItems={'center'} justifyContent={'space-between'}>
            <Header3 textsize='20px'>{modalTitle}</Header3>
            {!hideClose && (
              <IconButton onClick={handleClose}>
                <AiOutlineCloseCircle color={TEXT_COLOR_THEME.dark} />
              </IconButton>
            )}
          </Grid>
        </Grid>
        {!hideClose && (
          <Grid item xs={12} mt={'10px'}>
            <Divider />
          </Grid>
        )}
        <Grid item xs={12} mt={'16px'}>
          {children}
        </Grid>
      </Grid>
    );
  };

  const renderView = () => {
    if (modalType === 'react-hook') {
      return (
        <form
          onSubmit={(e) => {
            handleReactFormSubmit && handleReactFormSubmit(e);
            e.preventDefault();
          }}
        >
          {renderDialog()}
        </form>
      );
    }
    return renderDialog();
  };

  const renderDialog = () => {
    return (
      <>
        <DialogContent
          style={{
            padding: 0,
          }}
        >
          {renderModalInfo()}
        </DialogContent>
        {!hideDialogAction && (
          <DialogActions>
            <Grid container spacing={'20px'} mt={'10px'} justifyContent={'flex-end'}>
              {!hideClose && (
                <Grid item>
                  <PrimaryTextButton
                    style={{
                      borderRadius: 10,
                      width: 134,
                    }}
                    onClick={handleClose}
                    title={closeButtonLabel || 'Close'}
                  />
                </Grid>
              )}
              <Grid item>
                {modalType === 'react-hook' ? (
                  <PrimaryButton
                    style={{
                      borderRadius: 10,
                      width: 134,
                    }}
                    type='submit'
                    disabled={disableButton}
                    isLoading={saveLoading}
                    title={confirmButtonLabel || 'Save Edit'}
                  />
                ) : (
                  <PrimaryButton
                    style={{
                      borderRadius: 10,
                      width: 134,
                    }}
                    onClick={(e) => {
                      handleSubmit && handleSubmit();
                      e.preventDefault();
                    }}
                    isLoading={saveLoading}
                    disabled={disableButton}
                    title={confirmButtonLabel || 'Save'}
                  />
                )}
              </Grid>
            </Grid>
          </DialogActions>
        )}
      </>
    );
  };
  return (
    <Dialog
      open
      maxWidth={modalSize}
      PaperProps={{
        sx: {
          height: modalHeight || undefined,
        },
      }}
      fullWidth
      onClose={handleClose}
    >
      {renderView()}
    </Dialog>
  );
};

export default CustomModal;
