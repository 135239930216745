export const DUMMY_USER_DATA = {
  token:
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1bmlxdWVfSWQiOiJDOTMxRDAifQ.yns0zaAcLcBmHtJyJYgA5i0zXvXQQmJuRug2fnJ0jX4',
  userid: 2,
  username: 'test_account',
};

export const COMPLETED_TRANSACTION_IMPACT = {
  summary_validity: 'Valid',
  team1_pts_out: 1818,
  team2_pts_out: 2280,
  team1_netresult: 426,
  team2_netresult: -462,
  pick_exists_valididty: {
    check_pick_exists: 'Valid',
    picks_not_valid: [],
    description: 'This clause is valid.',
  },
  pick_match_validity: {
    check_picks_match: 'Warning',
    picks_dont_match: [
      {
        old_pick: '2024-RD1-Pick5-ESS',
        new_pick: '2024-RD1-Pick13-ESS',
      },
    ],
    description:
      'One or more picks have moved positions. Trade can still process but with updated pick numbers',
  },
  trade_back_rule_validity: {
    trade_back_rule: 'Valid',
    cannot_trade_back: [],
    description: 'This clause is valid.',
  },
};

export const CONTRACT_DATA = [
  {
    club: 'Manchester United',
    startDate: '2023-07-01',
    endDate: '2025-06-30',
    signingDate: '2023-06-30',
    contractType: 'Player',
    source: 'Transfermarkt',
  },
  {
    club: 'Real Madrid',
    startDate: '2022-08-01',
    endDate: '2024-07-31',
    signingDate: '2022-07-30',
    contractType: 'Coach',
    source: 'Official Announcement',
  },
  {
    club: 'FC Barcelona',
    startDate: '2023-01-01',
    endDate: '2025-12-31',
    signingDate: '2022-12-31',
    contractType: 'Player',
    source: 'Club Statement',
  },
  {
    club: 'Bayern Munich',
    startDate: '2024-02-01',
    endDate: '2026-01-31',
    signingDate: '2024-01-31',
    contractType: 'Coach',
    source: 'Media Reports',
  },
  {
    club: 'Liverpool',
    startDate: '2023-06-01',
    endDate: '2025-05-31',
    signingDate: '2023-05-31',
    contractType: 'Player',
    source: 'Transfer Window News',
  },
];

export const ACCOLADE_DUMMY_DATA = [
  {
    season: '2021-2022',
    award: 'Premier League Player of the Season',
  },
  {
    season: '2020-2021',
    award: 'Champions League Top Scorer',
  },
  {
    season: '2019-2020',
    award: 'La Liga Best Goalkeeper',
  },
  {
    season: '2018-2019',
    award: 'Serie A Team of the Season',
  },
  {
    season: '2017-2018',
    award: 'Bundesliga Player of the Year',
  },
];

export const PLAYER_MANAGER_DATA = [
  {
    manager: 'John Smith',
    startDate: '2022-01-01',
    endDate: '2023-12-31',
    company: 'Elite Sports Management',
  },
  {
    manager: 'Emily Johnson',
    startDate: '2023-03-15',
    endDate: '2024-03-14',
    company: 'Pro Athlete Management',
  },
  {
    manager: 'David Brown',
    startDate: '2021-06-10',
    endDate: '2022-06-09',
    company: 'Global Sports Agency',
  },
  {
    manager: 'Sarah Wilson',
    startDate: '2024-02-20',
    endDate: '2025-02-19',
    company: 'Sports Management Inc.',
  },
  {
    manager: 'Michael Roberts',
    startDate: '2022-09-01',
    endDate: '2023-08-31',
    company: 'Top Talent Agency',
  },
];

export const ELEGIBILITY_DUMMY_DATA = [
  {
    season: '2021-2022',
    eligibilityType: 'Work Permit',
    eligibilityStatus: 'Granted',
  },
  {
    season: '2020-2021',
    eligibilityType: 'Injury',
    eligibilityStatus: 'Fit',
  },
  {
    season: '2019-2020',
    eligibilityType: 'Suspension',
    eligibilityStatus: 'Served',
  },
  {
    season: '2018-2019',
    eligibilityType: 'Citizenship',
    eligibilityStatus: 'Approved',
  },
  {
    season: '2017-2018',
    eligibilityType: 'Loan',
    eligibilityStatus: 'End',
  },
];
