import { usePlayerManager } from '../hook';
import { Grid } from '@mui/material';
import DataGridWrapper from '../../../common/component/DataGridWrapper';
import { PlayerManagerListColumn } from './ListColumn';

const PlayerManager = () => {
  const { data, loading } = usePlayerManager();
  return (
    <Grid container>
      <Grid item xs={12}>
        <DataGridWrapper
          columns={PlayerManagerListColumn(
            () => {},
            () => {},
          )}
          rows={data}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
};

export default PlayerManager;
